jQuery(function () {
  'use strict';

  var NEW_WINDOW_MODAL_CLASS = 'new-window-modal';
  var app = {
    el: {
      body: jQuery('body'),
      mainForm: jQuery('.main-form'),
      pageLoader: jQuery('.page-loader-wrapper'),
      commitBtn: jQuery('.btn-commit'),
      nextBtn: jQuery('.btn-next'),
    },
    run: function () {
      this.checkIfModalInNewWindow();
      this.checkOperatingSystem();
      this.addNavigationMarker();
      this.bindEvents();
    },
    addNavigationMarker: function () {
      this.el.mainForm.prepend(
        '<input type="hidden" name="navigationMarker" id="navigationMarker" value="bottom" />',
      );
    },
    bindEvents: function () {
      this.el.body.on('click', '.modal .modal-wrapper > .close', function () {
        Utils.modal.close();
      });
      this.el.body.on('click', '.alert .close-alert', function (e) {
        Utils.smarthelp.close(e);
      });
      this.el.body.on(
        'click',
        '[data-toggle="modal"]',
        jQuery.proxy(function (e) {
          this.onModalOpenClick(e);
        }, this),
      );

      this.el.commitBtn.on(
        'click',
        jQuery.proxy(function (e) {
          this.onTopSave(e);
        }, this),
      );
      this.el.nextBtn.on(
        'click',
        jQuery.proxy(function (e) {
          this.onTopNext(e);
        }, this),
      );

      this.el.body.keydown(
        jQuery.proxy(function (e) {
          this.onKeyDown(e);
        }, this),
      );
    },
    registerTopNavigationUsage: function () {
      jQuery('#navigationMarker').val('top');
    },
    checkIfModalInNewWindow: function () {
      if (
        jQuery('.modal-body').length > 0 &&
        jQuery('.modal-wrapper').length < 1 &&
        jQuery('.modal').length < 1
      ) {
        this.el.body.addClass(NEW_WINDOW_MODAL_CLASS);
      }
    },
    checkOperatingSystem: function () {
      if (navigator.userAgent.indexOf('Mac OS X') != -1) {
        this.el.body.addClass('mac');
      } else {
        this.el.body.addClass('pc');
      }
    },
    onModalOpenClick: function (e) {
      e.preventDefault();

      var targetBtn = jQuery(e.currentTarget);
      var dataOptions = targetBtn.data();

      var url = targetBtn.attr('href'),
        height = dataOptions.height,
        width = dataOptions.width,
        steps = dataOptions.steps || null,
        auto = dataOptions.auto || false,
        modals = dataOptions.modals || null,
        backdrop = dataOptions.backdrop,
        refresh = dataOptions.refresh || null;

      if (url?.indexOf('#') !== 0) {
        Utils.modal.open(
          url,
          height,
          width,
          auto,
          modals,
          steps,
          backdrop,
          refresh,
        );
      }
    },
    onKeyDown: function (e) {
      if (e.keyCode === 27 && jQuery('.modal.modal-backbone').length === 0) {
        jQuery('.closeFilemanager')?.trigger('click');
        Utils.modal.close();
      }
    },
    onTopSave: function (e) {
      if (jQuery('#search').val()) {
        jQuery('.btn-clear').trigger('click');
      }
      if (jQuery(e.currentTarget).hasClass('disabled')) {
        return false;
      }

      this.el.mainForm.submit();
    },
    onTopNext: function (e) {
      if (jQuery('#search').val()) {
        jQuery('.btn-clear').trigger('click');
      }

      if (jQuery(e.currentTarget).hasClass('disabled')) {
        return false;
      }

      if (jQuery(e.currentTarget).hasClass('creator-action')) {
        this.registerTopNavigationUsage();
      }

      this.el.mainForm.prepend(
        '<input type="hidden" name="btnnext" value="1" />',
      );
      this.el.mainForm.submit();
    },
  };

  app.run();
});
