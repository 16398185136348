var heightLastInfo = 0;
var start = 0;

jQuery.fn.hasAttr = function (name) {
  return this.attr(name) !== undefined;
};

var Loader = new Object();
Loader.on = function (anim) {
  anim = typeof anim !== 'undefined' ? anim : true;
  jQuery('div#lightsOn').remove();
  jQuery('body').append(jQuery('<div>').attr('id', 'lightsOn'));

  if (FM.animate && anim) {
    jQuery('.page-loader').show();
    jQuery('.page-loader-wrapper').show();
    jQuery('#lightsOn').show(500);
  } else {
    jQuery('.page-loader').show();
    jQuery('#lightsOn').show();
  }
};

Loader.off = function (anim) {
  anim = typeof anim !== 'undefined' ? anim : true;
  if (FM.animate && anim) {
    jQuery('.page-loader').hide();
    jQuery('.page-loader-wrapper').hide();
    jQuery('#lightsOn').hide(500);
  } else {
    jQuery('.page-loader').hide();
    jQuery('#lightsOn').hide();
  }
};

// -------- aliasy ---------------------------------------------------------

Loader.show = Loader.on;
Loader.hide = Loader.off;

// -------------------------------------------------------------------------

var LoaderNoLights = new Object();
LoaderNoLights.on = function (anim) {
  var anim = typeof anim !== 'undefined' ? anim : true;
  jQuery('body').append(jQuery('<div>').attr('id', 'lightsOut'));

  if (FM.animate && anim) {
    jQuery('.page-loader').show();
    jQuery('.page-loader-wrapper').show();
    jQuery('#lightsOut').show(500);
  } else {
    jQuery('.page-loader').show();
    jQuery('#lightsOut').show(500);
  }
};

LoaderNoLights.off = function (anim) {
  var anim = typeof anim !== 'undefined' ? anim : true;
  if (FM.animate && anim) {
    jQuery('.page-loader').hide();
    jQuery('.page-loader-wrapper').hide();
    jQuery('#lightsOut').hide();
  } else {
    jQuery('.page-loader').hide();
    jQuery('.page-loader-wrapper').hide();
    jQuery('#lightsOut').hide();
  }
};

// -------- aliasy ---------------------------------------------------------

LoaderNoLights.show = LoaderNoLights.on;
LoaderNoLights.hide = LoaderNoLights.off;

// -------------------------------------------------------------------------

jQuery(document).ready(function ($) {
  jQuery('.btn-link').bind('click', function (e) {
    if (jQuery(this).hasClass('disabled')) {
      return false;
    }

    if (jQuery(this).attr('href')) {
      if (Utils.form.confirmEvent(jQuery(this), e) == true) {
        window.location = jQuery(this).attr('href');
      }
    }

    return false;
  });

  jQuery('.btn-special').bind('click', function () {
    if (jQuery(this).hasClass('disabled')) {
      return false;
    }
  });

  jQuery('.on-off').each(function () {
    if (jQuery(this).find('input:first').val() > 0) {
      jQuery(this).trigger('click');
    }
  });

  // Obsługa inputów z symbolami
  jQuery('.symbols-container-inner li').click(function () {
    var inputContext = jQuery(this).parents('.btn-group').find('input:text');
    var text = jQuery(this).text();
    jQuery(inputContext).insertAtCaret(text, '');
    jQuery(this).closest('.dropdown-menu').removeClass('open').hide();
  });

  //set default active button
  jQuery('div.btn-group:not(".static")').each(function () {
    jQuery(this)
      .children('button.btn')
      .val(jQuery(this).children('input').val());
  });

  jQuery('div.btn-group:not(".static") button.btn').bind('click', function () {
    jQuery(this).siblings().removeClass('active');
    jQuery(this).addClass('active');
    jQuery(this)
      .siblings('input[type="hidden"]')
      .val(jQuery(this).attr('val'))
      .trigger('change');
    return false;
  });

  jQuery('div.btn-group:not(".static") button.btn.active').each(function () {
    jQuery(this)
      .siblings('input[type="hidden"]')
      .val(jQuery(this).attr('val'))
      .trigger('change');
  });

  jQuery('.dynamic-btn-group')
    .find('button.active')
    .each(function () {
      jQuery(this).siblings('input[type="hidden"]').trigger('change');
    });

  //smarthelps buttons
  if (jQuery('.alert-open').length > 0) {
    if (jQuery('.alert-outer:visible').length > 0) {
      jQuery('.alert-open').addClass('active');
    }
  }

  // activate niceScroll at all the menu bars
  ({
    _hasMenuBar: function () {
      return $('aside.span3').find('#sideNav').length === 1;
    },
    run: function () {
      if (this._hasMenuBar()) {
        $('aside.span3').niceScroll('#sideNav');
      }
    },
  }.run());
});

function disableSaveButtons() {
  Utils.form.disableButtons();
}

function enableSaveButtons() {
  Utils.form.enableButtons();
}

var Validation = new Object();
Validation.rules = new Object();

Validation.firstError = function () {
  if (jQuery('.error').length) {
    jQuery(window).scrollTop(jQuery('.error:first').offset().top - 100);
    jQuery('.error:first').find('input').focus();
  }
};

Validation.rules.email = function (value) {
  var value = jQuery.trim(value);

  if (value == '') {
    return false;
  }

  var emailReg = new RegExp(/^([\w-+\.]+@([\w-]+\.)+[\w-]{2,20})?$/); // 20 is for functional domains

  if (!emailReg.test(value)) {
    return false;
  }

  return true;
};

Validation.rules.domain = function (value) {
  var value = jQuery.trim(value);
  if (value == '') {
    return false;
  }

  var domainReg = new RegExp(
    /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
  );
  return domainReg.test(value);
};

Validation.rules.email_or_personalization = function (value) {
  if (value == '') {
    return false;
  }

  if (value.indexOf('$$') >= 0) {
    var regex = new RegExp(/\$\$([^$]+)\$\$/);
  } else {
    var regex = new RegExp(/^([\w-+\.]+@([\w-]+\.)+[\w-]{2,})?$/);
  }

  if (!regex.test(value)) {
    return false;
  }

  return true;
};

Validation.rules.maxlength = function (value) {
  if (value.length > 255) {
    return false;
  } else {
    return true;
  }
};

Validation.rules.empty = function (value) {
  if (jQuery.trim(value) == '') {
    return false;
  } else {
    return true;
  }
};

Validation.rules.date_format = function (value) {
  var regex = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
  if (regex.test(value) == false) {
    return false;
  } else {
    return true;
  }
};

Validation.rules.empty_or_date_format = function (value) {
  if (jQuery.trim(value) == '') {
    return true;
  } else {
    return Validation.rules.date_format(value);
  }
};

//validacja imion w jezyku polskim
Validation.rules.name = function (value) {
  var regex = new RegExp(/[^A-Z��a-z�����]/g);
  if (jQuery.trim(value) == '' || regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

Validation.rules.url = function (value, pro) {
  /*
   * @pro - okresla czy protokul na poczatku adresu ma byc wymagany, domyslnie jest wymagany (true)
   */
  var pro = typeof pro !== 'undefined' ? pro : true;
  if (pro) {
    var regex =
      /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\[\]]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\]\[]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\[\]]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\]\[]|:|@)|\/|\?)*)?$/i;
  } else {
    var regex =
      /^((https?|s?ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\[\]]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\[\]]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\[\]]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\]\[]|:|@)|\/|\?)*)?$/i;
  }
  return regex.test(value);
};

Validation.rules.skype = function (value) {
  var regex = /^(skype):/i;
  return regex.test(value);
};

Validation.rules.mailto = function (value) {
  var regex = /^(mailto:)*/i;
  if (regex.test(value) == false) {
    return false;
  }

  var email_and_params = value.split(':');
  if (email_and_params.length < 2) {
    return false;
  }

  var email = email_and_params[1].split('?');
  return Validation.rules.email(email[0]);
};

Validation.rules.url_or_empty = function (value) {
  if (value == '' || Validation.rules.url(value)) {
    return true;
  }

  return false;
};

Validation.rules.url_or_mailto = function (value) {
  if (Validation.rules.url(value) || Validation.rules.mailto(value)) {
    return true;
  }

  return false;
};

Validation.rules.url_or_mailto_or_empty = function (value) {
  if (
    value == '' ||
    Validation.rules.url(value) ||
    Validation.rules.mailto(value)
  ) {
    return true;
  }

  return false;
};

/**
 * Validator ktory odsiewa wszytskie tamaty wiadomosci zaczynajace sie od "pwd", "owd", czy "odp"
 * @param value
 * @returns {boolean}
 */
Validation.rules.notAllowedTopic = function (value, object) {
  var $regTopicPattern = new RegExp('^(re:|odp:|fwd:)');
  value = jQuery.trim(value).toLowerCase();
  if (value == '') {
    object.attr('data-content-error', FM.l10n.validation.emptyTopic);
    return false;
  } else if ($regTopicPattern.test(value)) {
    object.attr('data-content-error', FM.l10n.validation.notAllowedTopic);
    return false;
  }
  return true;
};
Validation.rules.forbiddenDomain = function (value, object) {
  var domain = value.slice(value.indexOf('@') + 1),
    forbiddenDomains = object.data('forbiddenDomains'),
    forbiddenDomainArray = forbiddenDomains.split(', ');
  if (Validation.rules.email(value)) {
    if (_.indexOf(forbiddenDomainArray, domain) != -1) {
      object.attr(
        'data-content-error',
        FM.l10n.register.forbidden_domains.error,
      );
      return false;
    }
    return true;
  }
  return false;
};

Validation.rules.userPassword = function (value) {
  var numberChar = new RegExp(/\d+/g);
  var smallChar = new RegExp(/[a-z]/g);
  var capitalChar = new RegExp(/[A-Z]/g);
  var specialChar = new RegExp(/[^a-zA-Z0-9]/g);
  var multiByte = new RegExp(/[^\u0000-\u007F]/g);
  var pass = value;
  var valid = true;

  if (multiByte.test(pass)) {
    jQuery('i.mb').closest('li').show();
    valid = false;
  } else {
    jQuery('i.mb').closest('li').hide();
  }

  if (!smallChar.test(pass) || !capitalChar.test(pass)) {
    jQuery('i.letterSize').removeClass('png-correct').addClass('png-incorrect');
    valid = false;
  } else {
    jQuery('i.letterSize').removeClass('png-incorrect').addClass('png-correct');
  }

  if (!specialChar.test(pass) && !numberChar.test(pass)) {
    jQuery('i.numbersOrSpecials')
      .removeClass('png-correct')
      .addClass('png-incorrect');
    valid = false;
  } else {
    jQuery('i.numbersOrSpecials')
      .removeClass('png-incorrect')
      .addClass('png-correct');
  }

  if (pass.length < 8) {
    jQuery('i.size8').removeClass('png-correct').addClass('png-incorrect');
    valid = false;
  } else {
    jQuery('i.size8').removeClass('png-incorrect').addClass('png-correct');
  }
  return valid;
};

Validation.rules.letters = function (value) {
  var regex = new RegExp(
    /^[^0-9_@\$\+%&\!"#'\(\)\*,\.:;<=>?¡\^\{\}\\\[~\]\|\/\n\r]+$/g,
  );
  return jQuery.trim(value) !== '' && regex.test(value);
};

Validation.rules.address = function (value) {
  var regex = new RegExp(
    /^[^@\$\+%&\!"#'\(\)\*,:;<=>?¡\^\{\}\\\[~\]\|\n\r]+$/g,
  );
  return jQuery.trim(value) !== '' && regex.test(value);
};

Validation.onFocusOut = function (obj) {
  if (
    (obj.hasAttr('automated-form') == false && obj.val().trim() == false) ||
    (obj.attr('jsvalidate') &&
      Validation.rules[obj.attr('jsvalidate')](obj.val(), obj) == false)
  ) {
    obj.parent().addClass('error');
    obj.attr('data-content', obj.attr('data-content-error'));
    obj.data('content', obj.attr('data-content-error'));
    obj.removeClass('white').addClass('wrong');

    Utils.popover.refresh();
    return false;
  } else {
    obj.parent().removeClass('error');
    obj.attr('data-content', obj.attr('data-content-ok'));
    obj.data('content', obj.attr('data-content-ok'));
    obj.removeClass('wrong').addClass('white');

    Utils.popover.refresh();
    return true;
  }
};

var Success = new Object();
Success.html =
  '<div class="widget-static-wrapper" style="display: none">\
<div class="widget-static">\
<div class="widget-inner">\
<div class="widget-content">\
<div class="alert-widget">\
<svg class="icon green size32"><use xlink:href="#icon-fm-ok-badge"/></svg>\
<h3>__title__</h3>__description__\
</div>\
</div>\
</div>\
</div>\
</div>';

Success.show = function (description, title) {
  if (typeof title == 'undefined') {
    title = FM.l10n.common.success_alert;
  }

  description = jQuery('<div />').html(description).text();
  title = jQuery('<div />').html(title).text();

  html = Success.html.replace(/__title__/, title);
  html = html.replace(/__description__/, description);
  toggleInfoModal(html);
};

var Question = new Object();
Question.action = '';
Question.messages = new Array();
Question.html =
  '<div class="widget-static widget-question questionDial" style="display: none;z-index: 9999999;">\
    <div class="widget-content-wrapper">\
      <button type="button" class="changes-cancel-btn cancel">\
          <svg>\
            <use xlink:href="#icon-fm-popup-close"></use>\
          </svg>\
      </button>\
      <div class="widget-inner">\
        <div class="widget-content text-center">\
          <h3 class="second m0">__title__</h3>\
          <p>__description__</p>\
        </div>\
      </div>\
      <div class="widget-inner-bottom">\
        <a class="cancel"><%= cancel %></a>\
        <a class="fm-btn fm-btn-primary delete-btn ok">__button__</a>\
      </div>\
    </div>\
  </div>';

Question.askAboutDomainHtml =
  '<div class="widget-static widget-question questionDial" style="display: none;z-index: 9999999;"><div class="widget-content-wrapper"><div class="widget-inner">\
<div class="widget-content">\
<div class="alert-widget text-center">\
<svg class="icon size32"><use xlink:href="#icon-fm-alert"/></svg><h3 class="second white">__title__</h3> __description__\
</div>\
<div class="text-center">\
<div class="widget-content-bottom">\
<ul class="pager">\
<li>\
<a class="btn btn-secondary cancel block" href="">__cancel__</a>\
</li>\
</ul>\
</div>\
</div>\
</div>\
</div>\
</div>\
</div>';

Question.show = function (
  title,
  description,
  button,
  action,
  callback,
  dataToSend,
  event,
) {
  dataToSend = typeof dataToSend !== 'undefined' ? dataToSend : null;

  Question.action = action;
  if (typeof title == 'undefined') {
    title = 'Czy na pewno chcesz to zrobi�?';
  }

  var absolute = false;
  if (event == false) {
    x = window.innerWidth / 2;
    y = window.innerHeight / 2;
  } else if (event.clientX || event.clientY) {
    x = event.clientX;
    y = event.clientY;
    absolute = false;
  } else {
    //jesli nie ma clientX to znaczy ze event nie byl click'iem, pobieram offset elementu i ustawiam tooltipa na absoluta
    y = jQuery(event.target).offset().top;
    x = jQuery(event.target).offset().left;
    absolute = true;
  }

  var html = Question.html.replace(/__title__/, title);
  html = html.replace(/__description__/, description);
  html = html.replace(/__button__/, button);
  html = _.template(html)({ cancel: FM.l10n.changes.cancel });

  jQuery('body').find('.questionDial').remove();
  jQuery('body').append(html);

  if (absolute) {
    jQuery('.questionDial').css('position', 'absolute');
  }

  if (
    parseInt(event.clientX) + jQuery('.questionDial').width() >
      jQuery(window).width() ||
    absolute
  ) {
    x -= jQuery('.questionDial').width();
  } else if (event === false) {
    x -= jQuery('.questionDial').width() / 2;
  }

  if (
    parseInt(event.clientY) + jQuery('.questionDial').height() >
      jQuery(window).height() ||
    absolute
  ) {
    y -= jQuery('.questionDial').height();
  } else if (event === false) {
    y -= jQuery('.questionDial').height() / 2;
  }

  jQuery('.questionDial').fadeIn(500);

  document.addEventListener('click', function (e) {
    if (e?.target?.className?.includes('questionDial')) {
      document.querySelector('.questionDial').style.display = 'none';
    }
    return false;
  });

  jQuery('.questionDial')
    .find('.cancel')
    .bind('click', function (e) {
      jQuery(this).closest('.widget-static').fadeOut();
      return false;
    });

  jQuery('.questionDial')
    .find('.ok')
    .bind('click', function () {
      var button = jQuery(this);
      if (button.hasClass('disabled')) {
        return false;
      }
      button.attr('disabled', 'disabled');
      button.addClass('disabled');
      if (
        typeof objAjaxQueue == 'undefined' ||
        !(objAjaxQueue instanceof ajaxQueue)
      ) {
        objAjaxQueue = new ajaxQueue();
      }

      button.addClass('disabled');
      objAjaxQueue.addAjax({
        url: Question.action,
        data: dataToSend,
        dataType: 'json',
        type: 'post',
        success: function (response, e) {
          callback(response, e);
          button.closest('.questionDial').fadeOut(500, function () {
            jQuery(this).remove();
          });
        },
      });
    });
};

/**
 * Niektore domeny powoduja problemy z dostarczalnoscia. Rekomendujemy ich nieużywanie.
 * Uzytkownik musi sie zadeklarowac ze chce mimo wszytko uzyć tej domeny.
 */
Question.askForDomainRecommended = function (event, customParams) {
  var abortDomainAttrName = 'abortDomainAlert';
  var showBtnVerifyAttrName = 'showDomainVerify';

  // default params
  var params = {
    config: {
      domainNotRecommendedEnabled: false,
      domainNotRecommended: {},
      restrictedDomains: {},
      isFreePlan: true,
    },
    emailInput: jQuery('input[name="sender_email_address"]'),
    title: FM.l10n.parameters.attention,
    button: FM.l10n.parameters.ok,
    eventAfter: jQuery.Event('submit', {
      clientX: parseInt((jQuery(window).innerWidth() - 300) / 2, 10),
      clientY: parseInt(
        0.382 * jQuery(window).innerHeight() + jQuery(window).scrollTop(),
        10,
      ),
    }),
    callbackOk: null,
  };

  jQuery.extend(params, customParams);

  if (params.config.domainNotRecommendedEnabled != true) {
    return false;
  }

  if (params.emailInput.attr(abortDomainAttrName) == 1) {
    // uzytkownik juz kliknal, ze bedzie weryfikowal domene
    // dlatego nie pokazujemy modala
    return false;
  }

  var extendedCallbackOk = function () {
    params.emailInput.attr(abortDomainAttrName, 1);
    params.emailInput.attr(showBtnVerifyAttrName, 1);

    if (params.callbackOk) {
      params.callbackOk();
    }
  };

  var email = params.emailInput.val();
  var domain = email.substring(email.indexOf('@') + 1);
  var description = _.template(FM.l10n.parameters.emailNotRecommended)({
    domain: domain,
  });

  var isDomainRestricted = false;

  if (
    _.pluck(params.config.restrictedDomains, 'domain').indexOf(domain) !== -1
  ) {
    isDomainRestricted = true;
  }

  var html = Question.askAboutDomainHtml.replace(/__title__/, params.title);
  html = html.replace(/__description__/, description);
  html = html.replace(/__button__/, params.button);

  if (isDomainRestricted || !params.config.isFreePlan) {
    html = html.replace(/__cancel__/, FM.l10n.parameters.cancelObligatory);
  } else {
    html = html.replace(/__cancel__/, FM.l10n.parameters.cancel);
  }
  html = _.template(html)({ cancel: FM.l10n.changes.cancel });

  if (typeof callbackCancel == 'undefined') {
    var callbackCancel = function () {
      $(document).find(params.emailInput).focus();
    };
  }

  if (
    isDomainRestricted ||
    _.pluck(params.config.domainNotRecommended, 'domain').indexOf(
      domain.toLowerCase(),
    ) !== -1
  ) {
    params.emailInput.attr(showBtnVerifyAttrName, 0);
    Question.ask(
      params.title,
      description,
      params.button,
      extendedCallbackOk,
      params.eventAfter,
      callbackCancel,
      params.cancel,
      html,
    );
    if (event) {
      event.preventDefault();
    }
  } else {
    params.emailInput.attr(showBtnVerifyAttrName, 1);
  }
};

/*
 * uniwersalny okno dialogowe, po przycisnieciu ok wywolywany jest callback
 */
Question.ask = function (
  title,
  description,
  button,
  callbackOk,
  event,
  callbackCancel,
  cancel,
  html,
) {
  var minBottomDistance = 20;

  if (typeof title == 'undefined') {
    title = 'Czy na pewno chcesz to zrobić?';
  }

  if (typeof html == 'undefined') {
    var html = Question.html.replace(/__title__/, title);
    html = html.replace(/__description__/, description);
    html = html.replace(/__button__/, button);
    html = _.template(html)({ cancel: FM.l10n.changes.cancel });
  }

  jQuery('body').find('.questionDial').remove();
  jQuery('body').append(html);

  var questionDial = jQuery('.questionDial');
  if (cancel) {
    if (typeof cancel == 'function') {
      cancel(questionDial);
    }
  }

  if (event == false) {
    var x = window.innerWidth / 2 - questionDial.width() / 2;
    var y = window.innerHeight / 2 - questionDial.height() / 2;
  } else {
    if (event.clientX || event.clientY) {
      var x = event.clientX;
      var y = event.clientY;
    } else if (event.target) {
      var y = jQuery(event.target).offset().top;
      var x = 400;
    } else {
      var x = window.innerWidth / 2 - questionDial.width() / 2;
      var y = window.innerHeight / 2 - questionDial.height() / 2;
    }
  }

  if (x + jQuery('.questionDial').width() > jQuery(window).width()) {
    x -= jQuery('.questionDial').width();
  }

  if (y + jQuery('.questionDial').height() > jQuery(window).height()) {
    y =
      jQuery(window).height() -
      jQuery('.questionDial').height() -
      minBottomDistance;
  }

  jQuery('.questionDial').fadeIn(500);

  document.addEventListener('click', function (e) {
    if (e?.target?.className?.includes('questionDial')) {
      document.querySelector('.questionDial').style.display = 'none';
    }
    return false;
  });

  jQuery('.questionDial .cancel').bind('click', function () {
    jQuery(this).closest('.widget-static').fadeOut();
    if (typeof callbackCancel == 'function') {
      callbackCancel();
    }
    return false;
  });

  jQuery('.questionDial .ok').bind('click', function () {
    jQuery('.questionDial').hide();
    if (typeof callbackOk == 'function') {
      callbackOk();
    }
    return true;
  });

  // approve on enter click
  var onEnter = function (e) {
    if (e.keyCode === 13) {
      jQuery('.questionDial .ok').trigger('click');
      jQuery(document).off('keydown', onEnter);
    }
  };

  jQuery(document).on('keydown', onEnter);
};

Question.restore = function (
  title,
  description,
  button,
  callback,
  cancelCallback,
  event,
) {
  if (typeof title == 'undefined') {
    title = 'Czy na pewno chcesz to zrobić?';
  }
  jQuery('body').append(
    '<div style="background: rgba(0, 0, 0, 0.898);bottom: 0;left: 0;position: fixed;right: 0;top: 0;z-index: 999998;" id="lightsOffSpecial"></div>',
  );

  var absolute = false;
  if (event.clientX || event.clientY) {
    var x = event.clientX;
    var y = event.clientY;
    absolute = false;
  } else {
    //jesli nie ma clientX to znaczy ze event nie byl click'iem, pobieram offset elementu i ustawiam tooltipa na absoluta
    var y = jQuery(event.target).offset().top;
    var x = jQuery(event.target).offset().left;
    absolute = true;
  }

  var html = Question.html.replace(/__title__/, title);
  html = html.replace(/__description__/, description);
  html = html.replace(/__button__/, button);
  html = _.template(html)({ cancel: FM.l10n.changes.cancel });

  jQuery('body').find('.questionDial').remove();
  jQuery('body').append(html);

  if (absolute) {
    jQuery('.questionDial').css('position', 'absolute');
  }

  if (
    parseInt(event.clientX) + jQuery('.questionDial').width() >
      jQuery(window).width() ||
    absolute
  ) {
    x -= jQuery('.questionDial').width();
  }

  if (
    parseInt(event.clientY) + jQuery('.questionDial').height() >
      jQuery(window).height() ||
    absolute
  ) {
    y -= jQuery('.questionDial').height();
  }

  jQuery('.questionDial').fadeIn(500);

  document.addEventListener('click', function (e) {
    if (e?.target?.className?.includes('questionDial')) {
      document.querySelector('.questionDial').style.display = 'none';
    }
    return false;
  });

  jQuery('.questionDial .cancel').bind('click', function () {
    jQuery(this).closest('.widget-static').fadeOut();
    cancelCallback();
    jQuery('#lightsOffSpecial').hide();
    return false;
  });

  jQuery('.questionDial .ok').bind('click', function () {
    jQuery('.questionDial').hide();
    callback();
    jQuery('#lightsOffSpecial').hide();
    return true;
  });
};

window.Utils = new Object();

Utils.popover = new Object();
Utils.popover.on = function (parent) {
  parent.find('.btn-help-small').popover({
    template:
      '<div class="popover popover-white top"><div class="arrow"></div><div class="popover-inner top"><div class="popover-content"><p></p></div></div></div>',
    trigger: 'hover',
    container: 'body',
    animation: false,
    html: true,
    placement: getPlacement,
  });
};

Utils.popover.refresh = function () {
  jQuery('input.white').popover('destroy');
  jQuery('input.wrong').popover('destroy');
  jQuery('input.black').popover('destroy');
  jQuery('[data-view="campaign"]').popover('destroy');

  jQuery('input.wrong:not([data-content=""])').popover({
    template:
      '<div class="popover popover-wrong"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>',
    trigger: 'focus',
    container: 'body',
    html: true,
    placement: getPlacement,
  });
  jQuery('input.black').popover({
    template:
      '<div class="popover popover-black"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>',
    trigger: 'focus',
    container: 'body',
    html: true,
    placement: getPlacement,
  });
  jQuery('input.white:not([data-content=""])').popover({
    template:
      '<div class="popover popover-white"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>',
    trigger: 'focus',
    container: 'body',
    html: true,
    placement: getPlacement,
  });
  jQuery('.btn-help-small').popover({
    template:
      '<div class="popover popover-white top"><div class="arrow"></div><div class="popover-inner top"><div class="popover-content"><p></p></div></div></div>',
    html: true,
    trigger: 'hover',
    container: 'body',
    animation: false,
    placement: getPlacement,
  });
  jQuery('input[data-valid=check]').popover({
    template:
      '<div class="popover popover-white popover-check"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>',
    html: true,
    trigger: 'focus',
    placement: getPlacement,
  });
  jQuery('[description=true]').popover({
    template:
      '<div class="popover popover-white popover-description"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"></div></div></div>',
    trigger: 'click',
    html: true,
    placement: getPlacement,
  });
};

Utils.tutorial = new Object();
Utils.tutorial.counter = 1;
Utils.tutorial.objects = 0;
Utils.tutorial.current = false;
Utils.tutorial.name = '';
Utils.tutorial.action = '/help/ajax-do-not-show-me-tutorial-again';
Utils.tutorial.texts = new Object();
Utils.tutorial.force = false;

Utils.tutorial.add = function (object) {
  position = object.attr('tutorial-position');
  text = object.attr('tutorial-text');
  title = object.attr('tutorial-title'); //not necessery
  placement = object.attr('tutorial-placement'); //only to force placement, not necessery
  type = object.attr('tutorial-type'); //possible values: intro/outro, not necessery
  name = object.attr('tutorial-name'); //necessery only in one window, to switch off after reading
  noIntro = object.attr('tutorial-noIntro');

  if (text == false || parseInt(position) == 0) {
    return false;
  }

  if (typeof name != 'undefined' && name != '' && name != 'undefined') {
    Utils.tutorial.name = name;
  }

  if (typeof title == 'undefined') {
    title = '';
  }

  if (type == 'intro' || type == 'outro') {
    placement = 'intro';
  }

  Utils.tutorial.objects += 1;
  Utils.tutorial.texts[Utils.tutorial.objects] = new Object();
  Utils.tutorial.texts[Utils.tutorial.objects]['object'] = object;
  Utils.tutorial.texts[Utils.tutorial.objects]['text'] = text;
  Utils.tutorial.texts[Utils.tutorial.objects]['title'] = title;
  Utils.tutorial.texts[Utils.tutorial.objects]['placement'] = placement;
  Utils.tutorial.texts[Utils.tutorial.objects]['type'] = type;
  Utils.tutorial.texts[Utils.tutorial.objects]['position'] = position;
  Utils.tutorial.texts[Utils.tutorial.objects]['noIntro'] = noIntro ? 1 : 0;
};

Utils.tutorial.rewind = function () {
  Utils.tutorial.counter = 1;
};

Utils.tutorial.show = function (object) {
  jQuery('.bottom-fixed-alert').hide();

  if (typeof object['type'] == 'undefined') {
    //because of first (and last) "nontip" window
    var tip =
      Utils.tutorial.counter - 2 + parseInt(Utils.tutorial.texts[1]['noIntro']);
    var all = Utils.tutorial.objects;
    if (Utils.tutorial.name == 'designer') {
      //because of last, extra window
      //all--;
    }

    var html =
      '<div class="top-description-popover"><div class="top-description-popover-inner"><h2>' +
      object['title'] +
      '</h2><span class="subhead">' +
      FM.l10n.tutorial.tip +
      ' ' +
      tip +
      '/' +
      all +
      '</span></div><p>' +
      object['text'] +
      '</p></div><div class="bottom-description-popover"><a class=" btn-normal btn" id="showNextTutorial">' +
      FM.l10n.tutorial.next +
      '<svg class="icon green icon-smaller"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-fm-arrow-right"></use></svg></a> ' +
      FM.l10n.tutorial.or +
      ' <a id="doNotShowTutorialAgain" class="cpointer">' +
      FM.l10n.tutorial.exitNow +
      '</a></div>';
  } else if (object['type'] == 'outro') {
    //outro
    var html =
      '<div class="top-description-popover"><div class="top-description-popover-inner"><h2>' +
      object['title'] +
      '</h2></div><p>' +
      object['text'] +
      '</p></div><div class="bottom-description-popover"><a class=" btn-normal btn" id="showTutorialAgain">' +
      FM.l10n.tutorial.again +
      '<svg class="icon green icon-smaller"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-fm-arrow-right"></use></svg></a> ' +
      FM.l10n.tutorial.or +
      ' <a id="doNotShowTutorialAgain" class="cpointer">' +
      FM.l10n.tutorial.exit +
      '<svg class="icon green icon-smaller"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-fm-arrow-right"></use></svg></a></div>';
    if (Utils.tutorial.name == 'designer') {
      html =
        '<div class="top-description-popover"><div class="top-description-popover-inner"><h2>' +
        object['title'] +
        '</h2></div><p>' +
        object['text'] +
        '</p></div><div class="bottom-description-popover"><a class="btn-mini btn-normal btn" id="showLastArrow" style="margin: 5px;">' +
        FM.l10n.tutorial.exit +
        '<svg class="icon green icon-smaller"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-fm-arrow-right"></use></svg></a>' +
        FM.l10n.tutorial.or +
        '<a class="btn-mini btn-normal btn" id="showTutorialAgain"  style="margin: 5px;">' +
        FM.l10n.tutorial.again +
        '<svg class="icon green icon-smaller"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-fm-arrow-right"></use></svg></a></br><a target="_blank" href="http://freshmail.pl/wideo-przewodniki/#66331557" class="btn-mini btn-normal btn"  style="margin: 5px;">' +
        FM.l10n.tutorial.else +
        '<svg class="icon green icon-smaller"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-fm-arrow-right"></use></svg></a></div>';
    }
  } else if (object['type'] == 'intro') {
    //intro
    var html =
      '<div class="top-description-popover"><div class="top-description-popover-inner"><h2>' +
      object['title'] +
      '</h2></div><p>' +
      object['text'] +
      '</p></div><div class="bottom-description-popover"><a class=" btn-normal btn" id="showNextTutorial">' +
      FM.l10n.tutorial.next +
      '<svg class="icon green icon-smaller"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-fm-arrow-right"></use></svg></a></div>';
  } else if (object['type'] == 'extra') {
    //html = '<div class="top-description-popover"><div class="top-description-popover-inner"><h2>' + object['title'] + '</h2></div><p>' + object['text'] + '</p></div><div class="bottom-description-popover"><a class=" btn-normal btn" id="doNotShowTutorialAgain">' + FM.l10n.tutorial.ok + '<svg class="icon green icon-smaller"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-fm-arrow-right"></use></svg></a></div>';
  } else {
    return false;
  }

  // Zapamiętaj pierwotną treść tooltipa
  if (jQuery(object['object']).attr('data-content') !== undefined) {
    Utils.tutorial.current['original-content'] = jQuery(object['object']).attr(
      'data-content',
    );
  }

  jQuery(object['object']).attr('data-content', html);

  jQuery(object['object'])
    .popover('destroy')
    .popover({
      template:
        '<div class="popover popover-white ' +
        Utils.tutorial.name +
        object['position'] +
        ' popover-description tutorial-popover"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"></div></div></div>',
      trigger: 'manual',
      container: 'body',
      html: 'true',
      placement: getPlacement,
      content: html,
    });

  jQuery(object['object']).popover('show');
  Utils.tutorial.fixPlacement(object);
  Utils.tutorial.fixBackground(object);

  if (object['type'] != 'outro' && object['type'] != 'extra') {
    jQuery('#showNextTutorial').bind('click', function () {
      Utils.tutorial.next();
    });

    jQuery('#doNotShowTutorialAgain').bind('click', function () {
      Utils.tutorial.exit();
    });
  } else {
    jQuery('#showTutorialAgain').bind('click', function () {
      Utils.tutorial.rewind();
      Utils.tutorial.next();
    });

    jQuery('#doNotShowTutorialAgain').bind('click', function () {
      Utils.tutorial.exit();
    });

    jQuery('#showLastArrow').bind('click', function () {
      Utils.tutorial.next();
    });
  }

  // Poprawka, gdy popover jest przy krawędzi
  var tmpPopover = jQuery('.tutorial-popover');
  var popoverTopDist = tmpPopover.offset().top;
  var popoverBottomDist =
    jQuery(window).height() - popoverTopDist - tmpPopover.height();
  if (popoverTopDist < 0) {
    tmpPopover.css({ top: 0 });
    tmpPopover.find('.arrow').css({ top: 40 });
  }
  if (popoverBottomDist < 0) {
    tmpPopover.css({ top: 'auto', bottom: 0 });
    tmpPopover.find('.arrow').css({ top: 'auto', bottom: 50 });
  }
};

Utils.tutorial.start = function (force) {
  // Wyłączenie tutorial na czas przygotowania design system
  // if (typeof force != 'undefined' && force == true) {
  //     Utils.tutorial.force = true;
  // }
  //
  // Utils.tutorial.init();
  // if (Utils.tutorial.objects == 0) {
  //     return false;
  // }
  //
  // jQuery(window).scrollTop(0);
  // Utils.scroll.disable();
  // jQuery('.tutorial-overlay').show();
  //
  // Utils.tutorial.counter = 1;
  // Utils.tutorial.current = Utils.tutorial.texts[Utils.tutorial.counter];
  // Utils.tutorial.counter += 1;
  // Utils.tutorial.show(Utils.tutorial.current);
};

Utils.tutorial.destroyPrevious = function () {
  jQuery(Utils.tutorial.current['object']).popover('destroy');
  jQuery('.tutorial-popover').remove();

  if (Utils.tutorial.current['original-content'] !== undefined) {
    jQuery(Utils.tutorial.current['object']).attr(
      'data-content',
      Utils.tutorial.current['original-content'],
    );
    jQuery(Utils.tutorial.current['object']).popover();
  } else {
    jQuery(Utils.tutorial.current['object']).removeAttr('data-content');
  }
};

Utils.tutorial.next = function () {
  jQuery('.bottom-fixed-alert').show();

  Utils.tutorial.destroyPrevious();

  if (typeof Utils.tutorial.texts[Utils.tutorial.counter] == 'undefined') {
    jQuery('.tutorial-overlay').hide();
    Utils.scroll.enable();
    return false;
  }

  Utils.tutorial.current = Utils.tutorial.texts[Utils.tutorial.counter];
  Utils.tutorial.counter += 1;
  Utils.tutorial.show(Utils.tutorial.current);
};

Utils.tutorial.init = function () {
  var tmpTutorials = [];
  var skip_tutorial = false;

  jQuery('[tutorial-position]').each(function () {
    tmpTutorials.push({
      object: jQuery(this),
      position: jQuery(this).attr('tutorial-position'),
    });
    if (
      typeof jQuery(this).attr('tutorial-off') != 'undefined' &&
      jQuery(this).attr('tutorial-off') != ''
    ) {
      skip_tutorial = true;
    }
  });

  if (skip_tutorial == true && Utils.tutorial.force == false) {
    return false;
  }

  tmpTutorials.sort(function (a, b) {
    return parseFloat(a.position) - parseFloat(b.position);
  });

  for (var i = 0; i < tmpTutorials.length; i++) {
    Utils.tutorial.add(tmpTutorials[i].object);
  }
};

Utils.tutorial.exit = function () {
  //Utils.tutorial.next(); exit odbywa� si� w taki spos�b �e jak jestes w ostatnim kroku tutoriala i klikasz exit to odpala sie funkcja next ktora usuwa obecny popover tutoriala i odpala nast�pny... dzia�a tylko na ostatnim elemencie

  Utils.tutorial.destroyPrevious();

  jQuery('.tutorial-overlay').hide();
  Utils.scroll.enable();

  jQuery.ajax({
    url: '/' + FM.l10n.lang + Utils.tutorial.action,
    data: { name: Utils.tutorial.name },
    dataType: 'json',
    type: 'post',
    success: function (response, e) {},
  });
  jQuery('.bottom-fixed-alert').show();
};

Utils.tutorial.fixPlacement = function (object) {
  jQuery('.tutorial-popover').css({ 'z-index': 999999 });

  if (typeof object['placement'] == 'undefined') {
    return false;
  }

  var tmp = object['placement'].split(',');
  for (var i = 0; i < tmp.length; i++) {
    if (
      (tmp[i] == 'right' || tmp[i] == 'left') &&
      (jQuery('.tutorial-popover').hasClass('top') ||
        jQuery('.tutorial-popover').hasClass('bottom'))
    ) {
      object['placement'] = tmp[i];
    } else if (
      (tmp[i] == 'top' || tmp[i] == 'bottom') &&
      (jQuery('.tutorial-popover').hasClass('left') ||
        jQuery('.tutorial-popover').hasClass('right'))
    ) {
      object['placement'] = tmp[i];
    }
  }

  if (object['placement'] == 'right') {
    if (
      jQuery('.tutorial-popover').hasClass('left') ||
      jQuery('.tutorial-popover').hasClass('right')
    ) {
      return false;
    }

    shift = jQuery('.tutorial-popover').find('.popover-inner').width() / 2 - 35;
    jQuery('.tutorial-popover')
      .find('.popover-inner')
      .css({ position: 'relative', left: shift + 'px' });
  } else if (object['placement'] == 'left') {
    if (
      jQuery('.tutorial-popover').hasClass('left') ||
      jQuery('.tutorial-popover').hasClass('right')
    ) {
      return false;
    }

    shift = jQuery('.tutorial-popover').find('.popover-inner').width() / 2 - 35;
    jQuery('.tutorial-popover')
      .find('.popover-inner')
      .css({ position: 'relative', left: '-' + shift + 'px' });
  } else if (object['placement'] == 'bottom') {
    if (
      jQuery('.tutorial-popover').hasClass('bottom') ||
      jQuery('.tutorial-popover').hasClass('top')
    ) {
      return false;
    }

    shift =
      jQuery('.tutorial-popover').find('.popover-inner').height() / 2 - 20;
    jQuery('.tutorial-popover')
      .find('.popover-inner')
      .css({ position: 'relative', top: shift + 'px' });
  } else if (object['placement'] == 'center') {
    var left = jQuery(object['object']).width() / 2;
    var top = jQuery(object['object']).height() / 2;

    var currentLeft = parseInt(jQuery('.tutorial-popover').css('left'));
    var currentTop = parseInt(jQuery('.tutorial-popover').css('top'));

    if (
      jQuery('.tutorial-popover').hasClass('right') ||
      jQuery('.tutorial-popover').hasClass('left')
    ) {
      if (jQuery('.tutorial-popover').hasClass('right')) {
        left = 0 - left;
      }

      jQuery('.tutorial-popover').css({
        left: currentLeft + left + 'px',
        top: currentTop + 'px',
      });
    } else if (
      jQuery('.tutorial-popover').hasClass('top') ||
      jQuery('.tutorial-popover').hasClass('bottom')
    ) {
      if (jQuery('.tutorial-popover').hasClass('bottom')) {
        top = 0 - top;
      }

      jQuery('.tutorial-popover').css({
        left: currentLeft + 'px',
        top: currentTop + top + 'px',
      });
    }
  } else if (object['placement'] == 'intro') {
    if (
      object['type'] == 'outro' &&
      (Utils.tutorial.name == 'index' || Utils.tutorial.name == 'designer')
    ) {
      jQuery('.popover-inner').css('width', '410px');
    }

    var height =
      jQuery('.tutorial-popover').find('.popover-inner').height() / 2;
    var width = jQuery('.tutorial-popover').find('.popover-inner').width() / 2;

    var windowHeight = jQuery(window).height() / 2;
    var windowWidth = jQuery(window).width() / 2;

    jQuery('.tutorial-popover').css({
      left: windowWidth - width + 'px',
      top: windowHeight - height + 'px',
    });
    jQuery('.tutorial-popover').children('.arrow').remove();
    jQuery('.tutorial-overlay').css({
      'border-width': jQuery(window).width() / 2 + 'px',
    });
  }
};

Utils.tutorial.fixBackground = function (object) {
  if (object['type'] == 'intro' || object['type'] == 'outro') {
    jQuery('.tutorial-overlay').css({
      'border-width': jQuery(window).width() / 2 + 'px',
    });
    return false;
  }

  var offsetTop = object['object'].offset().top;
  var offsetLeft = object['object'].offset().left;
  var width = object['object'].innerWidth();
  var height = object['object'].innerHeight();
  var windowHeight = jQuery(window).height();
  var windowWidth = jQuery(window).width();

  var Btop = offsetTop - 10;
  var Bright = windowWidth - offsetLeft - width - 10;
  var Bbottom = windowHeight - offsetTop - height - 10;
  var Bleft = offsetLeft - 10;

  if (Btop < 0) {
    Btop = 0;
  }

  if (Bright < 0) {
    Bright = 0;
  }

  if (Bbottom < 0) {
    Bbottom = 0;
  }

  if (Bleft < 0) {
    Bleft = 0;
  }

  jQuery('.tutorial-overlay').css({
    'border-width':
      Btop + 'px ' + Bright + 'px ' + Bbottom + 'px ' + Bleft + 'px',
  });
};

Utils.scroll = new Object();
Utils.scroll.disable = function () {
  jQuery('body').css('overflow', 'hidden');
};

Utils.scroll.enable = function () {
  jQuery('body').css('overflow', '');
};

Utils.page = new Object();

Utils.page.getFromURL = function (id) {
  var url = location.pathname.split('/');
  return url[id];
};

Utils.page.language = Utils.page.getFromURL(1);
Utils.page.controler = Utils.page.getFromURL(2);
Utils.page.action = Utils.page.getFromURL(3);
