jQuery(function () {
  'use strict';

  var $loader = jQuery('.page-loader-wrapper');

  const body = jQuery('body');

  body.on('click', '#btn-logout', function (event) {
    event.preventDefault();
    $loader.show();
    jQuery.ajax({
      url: '/v2/user/authentication/logout',
      dataType: 'json',
      type: 'POST',
      success: function (response) {
        localStorage.setItem('userLogout', 'true');
        window.location.href = response.redirect;
      },
      error: function (error) {
        console.error(error.responseJSON.message);
        location.reload();
      },
    });
  });

  body.on('click', '#btn-logout-conflict', function () {
    localStorage.setItem('userLogout', 'true');
  });
});
