/*
 * Zamykanie wewn�trz SmartHelp
 */
Utils.smarthelp = new Object();
Utils.smarthelp.close = function (event) {
  var button = jQuery(event.currentTarget);
  var wrapper = button.parent().parent();

  wrapper.hide();

  if (jQuery('.alert-outer:visible').length == 0) {
    jQuery('.alert-open').removeClass('active');
  }

  jQuery.ajax({
    url: wrapper.attr('action'),
    data: {
      path: wrapper.attr('help'),
    },
    dataType: 'json',
    type: 'POST',
    success: function (response) {
      //do nothing - not important
    },
  });
};
