jQuery(function () {
  'use strict';

  var $window = jQuery(window),
    $body = jQuery('body'),
    $container = jQuery('body > .container'),
    $row = jQuery('body > .container > .row'),
    $helpAlerts = jQuery('.alert-info.alert-info-main'),
    $onOffs = jQuery('.on-off.form-widget-control'),
    $sideNav = jQuery('aside.span3'),
    $sideNavLinks = jQuery('aside.span3 ul.nav > li > a'),
    $activeSideNavLink = jQuery('aside.span3 ul.nav > li > a.active'),
    $mobileNavToggleBtn = jQuery('.nav .menu-toggle'),
    $sendActivationMailBtn = jQuery('a[name="send-again"]'),
    $activationCloseBtn = jQuery('a.js-close-activation'),
    $MMDBelt = jQuery('.mmd-bottom-belt'),
    $globalRebateBelt = jQuery('.global-rebate-belt'),
    $helloBelt = jQuery('.bottom-belt--hello'),
    $blogPostBelt = jQuery('.bottom-belt--blog-post'),
    $MMDCloseBtn = jQuery('a.js-close-mmd'),
    $globalRebateCloseBtn = jQuery('a.global-rebate-belt__close'),
    $WebinarBeltCloseBtn = jQuery('a.js-close-webinar-belt'),
    $helloCloseBtn = jQuery('a.js-close-hello'),
    $blogPostBeltCloseBtn = jQuery('a.js-close-blog-post'),
    $feedbackOpenBtn = jQuery('.btn.open-feedback'),
    $notificationsBadge = jQuery('a#notifications .badge'),
    $bottomBelt = jQuery('[data-bottom-belt]'),
    $bottomBeltCloseBtn = jQuery('[data-closing-cookie]'),
    $appRegionTemplates = jQuery('.app-region-templates'),
    $promoLiteBelt = jQuery('#promo-23-percent-lite');

  var fmDropdown = (function () {
    var fmDropdown = function () {};

    _.extend(fmDropdown.prototype, {
      setPosition: function (e) {
        (this.dropBtn = jQuery(e.currentTarget)),
          (this.dropMenu = this.dropBtn.siblings('.dropdown-menu')),
          (this.dropScrollOuter = this.dropMenu.find('.scroll-outer')),
          (this.dropScrollInner = this.dropMenu.find('.scroll-inner')),
          (this.topDistance =
            this.dropBtn.offset().top - $(window).scrollTop()),
          (this.bottomDistance = window.innerHeight - this.topDistance),
          (this.dropMenuHeight = this.dropMenu.height()),
          (this.dropMenuWidth = this.dropMenu.width()),
          (this.dropBtnHeight = this.dropBtn.innerHeight()),
          (this.navHeight = parseInt($body.css('paddingTop'), 10)),
          (this.dropMaxHeight = 300);

        if ($appRegionTemplates.height() <= 350) {
          $appRegionTemplates.height(540);
        }

        var self = this;

        if (this.dropScrollOuter.length > 0) {
          if (this.bottomDistance > 200) {
            this.setBottomPosition();
          } else {
            this.setTopPosition();
          }

          this.dropScrollOuter.css({
            'max-height': this.dropMaxHeight,
          });

          jQuery(this.dropScrollOuter).niceScroll(this.dropScrollInner);

          setTimeout(self.dropdownScrollRefresh, 1200);
        } else {
          if (this.bottomDistance < this.dropMenuHeight + 20) {
            this.setTopPosition();
          } else {
            this.setBottomPosition();
          }
        }
      },

      setBottomPosition: function () {
        this.dropMenu.removeClass('top-fixed');
        this.dropMaxHeight = window.innerHeight - this.topDistance - 60;

        this.dropMenu.css({
          top: this.dropBtnHeight + 5,
          bottom: 'auto',
        });
      },

      setTopPosition: function () {
        this.dropMenu.addClass('top-fixed');
        this.dropMaxHeight = this.topDistance - this.navHeight - 20;

        this.dropMenu.css({
          top: 'auto',
          bottom: this.dropBtnHeight + 10,
        });
      },

      dropdownScrollRefresh: function () {
        jQuery('.dropdown-menu .scroll-outer').getNiceScroll().resize();
      },
    });

    return fmDropdown;
  })();

  var fmTooltip = (function () {
    var fmTooltip = function () {
      this.init();
    };

    _.extend(fmTooltip.prototype, {
      templates: {
        defalut:
          '<div class="tooltip tooltip-default"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
        wrong:
          '<div class="popover popover-wrong"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>',
        white:
          '<div class="popover popover-white"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>',
        context:
          '<div class="popover popover-white top popover-menu" style="z-index:1000003;"><div class="arrow"></div><div class="popover-inner top"><div class="popover-content"><p></p></div></div></div>',
        validInfo:
          '<div class="popover popover-validInfo"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"></div></div></div>',
        check:
          '<div class="popover"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>',
      },
      init: function () {
        this.setTooltipsParams();
        this.bindHelpTooltips();
      },
      setTooltipsParams: function () {
        jQuery('[rel="popover"]').tooltip({
          placement: getPlacement,
        });
        jQuery('[data-original-title]').tooltip({
          placement: getPlacement,
        });
        jQuery('[rel=white]').tooltip({
          template: this.templates.defalut,
          placement: getPlacement,
          html: true,
        });
        jQuery('input.wrong:not([data-content=""])').popover({
          template: this.templates.wrong,
          trigger: 'focus',
          container: 'body',
          placement: getPlacement,
        });
        jQuery(
          'input.white:not([data-content=""]), textarea.info:not([data-content=""])',
        ).popover({
          template: this.templates.white,
          trigger: 'focus',
          container: 'body',
          placement: getPlacement,
          html: 'true',
        });
        jQuery('.context').popover({
          template: this.templates.context,
          html: true,
          container: 'body',
          trigger: 'click',
          placement: getPlacement,
        });
        jQuery('input[data-poptype=validInfo]').popover({
          template: this.templates.validInfo,
          trigger: 'focus',
          html: 'true',
          placement: getPlacement,
        });
        jQuery('input[data-valid=check]').popover({
          template: this.templates.check,
          trigger: 'focus',
          html: 'true',
          placement: getPlacement,
        });
      },
      bindHelpTooltips: function () {
        $body.on(
          'mouseenter',
          '.btn-help-small:not(".btn-help-notooltip")',
          jQuery.proxy(function (e) {
            this.onTooltipMouseOn(e);
          }, this),
        );
        $body.on(
          'mouseleave',
          '.btn-help-small:not(".btn-help-notooltip")',
          jQuery.proxy(function (e) {
            this.onTooltipMouseLeave(e);
          }, this),
        );
      },
      onTooltipMouseOn: function (e) {
        var tooltipBtn = jQuery(e.currentTarget);
        tooltipBtn.popover({
          container: 'body',
          html: true,
        });
        tooltipBtn.popover('show');
      },
      onTooltipMouseLeave: function (e) {
        var popoverButton = jQuery(e.currentTarget),
          popover = jQuery('.popover');

        setTimeout(function () {
          if (jQuery('.popover:hover').length < 1) {
            popoverButton.popover('hide');
          }
          popover.on('mouseleave', function () {
            jQuery(this).hide();
          });
        }, 50);
      },
    });

    return fmTooltip;
  })();

  var fmSticky = (function () {
    var fmSticky = function () {
      this.init();
    };

    _.extend(fmSticky.prototype, {
      lastScrollTop: 0,
      topSpacing: 60,
      scrollDownClassName: 'scrollDown',
      el: {
        $header: jQuery('header > .navbar'),
        $stickyHeader: jQuery('.app-sticky-header'),
      },
      init: function () {
        this.bindActions();
      },
      bindActions: function () {
        $window.on('resize', jQuery.proxy(this.updateStickyWidth, this));
        $window.on('scroll', jQuery.proxy(this.watchScollDirection, this));
      },
      updateStickyWidth: function () {
        var contentWidth = $body.innerWidth() - this.getBodyPadding();
        if (contentWidth < 960) {
          this.el.$stickyHeader.css('width', contentWidth);
        }
      },
      getBodyPadding: function () {
        return (
          parseInt($body.css('paddingRight')) +
          parseInt($body.css('paddingLeft'))
        );
      },
      watchScollDirection: function () {
        var currentScrollTop = $window.scrollTop();

        if (currentScrollTop < this.lastScrollTop || currentScrollTop < 150) {
          this.onScrollUp();
        } else {
          this.onScrollDown();
        }

        this.updateTopSpacing();
        this.lastScrollTop = currentScrollTop;
      },
      onScrollUp: function () {
        $body.removeClass(this.scrollDownClassName);
        this.topSpacing = $body.css('paddingTop');
      },
      onScrollDown: function () {
        $body.addClass(this.scrollDownClassName);
        this.topSpacing = this.el.$header.height();
      },
      updateTopSpacing: function () {
        var activeStickyHeader = jQuery(
          '.sticky-wrapper > div[style*="position: fixed"]',
        );

        if (activeStickyHeader.length > 0) {
          activeStickyHeader.css({ top: this.topSpacing });
        }
      },
    });

    return fmSticky;
  })();

  var fmMobile = (function (isMobile) {
    var fmMobile = function (isMobile) {
      this.isMobile = isMobile;
      this.init();
    };

    _.extend(fmMobile.prototype, {
      mobileNavToggleClassName: 'mobile-menu-shown',

      init: function () {
        if (this.isMobile === '1') {
          this.addMobileBodyClass();
          this.limitHelpAlertsHeight();
          this.bindSideNavSwipeEvents();
        }
      },

      addMobileBodyClass: function () {
        $body.addClass('mobile');
      },

      bindSideNavSwipeEvents: function () {
        if ($container.length >= 1 && $sideNav.length >= 1) {
          $row.attr('id', 'swipeWrapper');
          var $swipeContainer = document.getElementById('swipeWrapper');
          var hammertime = new Hammer($swipeContainer);

          hammertime.on('swiperight', jQuery.proxy(this.showSideNav, this));
          hammertime.on('swipeleft', jQuery.proxy(this.hideSideNav, this));
        }
      },

      showSideNav: function () {
        $body.addClass(this.mobileNavToggleClassName);
        this.hideSideNavOnInnerLinkClick();
      },

      hideSideNav: function () {
        $body.removeClass(this.mobileNavToggleClassName);
      },

      toggleSideNav: function (e) {
        e.preventDefault();

        if ($body.hasClass(this.mobileNavToggleClassName)) {
          this.hideSideNav();
        } else {
          this.showSideNav();
        }
      },

      hideSideNavOnInnerLinkClick: function () {
        var innerLinks = jQuery(
          'body.' +
            this.mobileNavToggleClassName +
            ' aside.span3 ul.round-menu:not(.folders-list) li > a',
        );
        innerLinks.on('click', jQuery.proxy(this.hideSideNav, this));
      },

      limitHelpAlertsHeight: function () {
        $helpAlerts.each(function () {
          var helpEl = jQuery(this);
          if (helpEl.height() > 100) {
            helpEl.addClass('expandable');
            helpEl.bind('click', function () {
              jQuery(this).toggleClass('expanded');
            });
          }
        });
      },
    });

    return fmMobile;
  })();

  var fmApp = (function () {
    var fmApp = function () {
      this.init();
    };

    _.extend(fmApp.prototype, {
      isMobile: FM.mobile,
      notifications: FM.notifications,

      init: function () {
        // UI Components
        this.fmDropdown = new fmDropdown();
        this.fmTooltip = new fmTooltip();
        this.fmSticky = new fmSticky();
        this.fmMobile = new fmMobile(this.isMobile);

        this.hideEmptyNotificationsBadge();
        this.animateRaportsNavLink();
        this.initLanguageToggles();

        // Popups and Ads
        this.showOccasionPop();
        this.showMMDBelt();
        this.showGlobalRebateBelt();
        this.showBlogPostBelt();
        this.showBottomBelt();

        if (this.pageHasSideNav()) {
          $mobileNavToggleBtn.removeClass('hide');
          this.openActiveSideNavSubmenu();
        }

        this.bindUIActions();
      },
      bindUIActions: function () {
        $body.on(
          'click',
          '[data-toggle="dropdown"]',
          jQuery.proxy(function (e) {
            this.fmDropdown.setPosition(e);
          }, this),
        );
        $mobileNavToggleBtn.find('a').on(
          'click',
          jQuery.proxy(function (e) {
            this.fmMobile.toggleSideNav(e);
          }, this),
        );
        $body.on('click', '.disabled', function () {
          return false;
        });
        $sideNavLinks.on(
          'click',
          jQuery.proxy(function (e) {
            this.openClickedSubmenu(e);
          }, this),
        );
        $activationCloseBtn.on(
          'click',
          jQuery.proxy(function (e) {
            this.closeBottomAlert(e);
          }, this),
        );
        $sendActivationMailBtn.on(
          'click',
          jQuery.proxy(function (e) {
            this.sendActivationMail(e);
          }, this),
        );
        $MMDCloseBtn.on(
          'click',
          jQuery.proxy(function (e) {
            this.onMMDBeltClose(e);
          }, this),
        );
        $globalRebateCloseBtn.on(
          'click',
          jQuery.proxy(function (e) {
            this.onGlobalRebateBeltClose(e);
          }, this),
        );
        $WebinarBeltCloseBtn.on(
          'click',
          jQuery.proxy(function (e) {
            this.onWebinarBeltClose(e);
          }, this),
        );
        $helloCloseBtn.on(
          'click',
          jQuery.proxy(function (e) {
            this.onHelloBeltClose(e);
          }, this),
        );
        $blogPostBeltCloseBtn.on(
          'click',
          jQuery.proxy(function (e) {
            this.onBlogPostBeltClose(e);
          }, this),
        );
        $bottomBeltCloseBtn.on(
          'click',
          jQuery.proxy(function (e) {
            this.onBottomBeltClose(e);
          }, this),
        );
        $onOffs.on(
          'click',
          jQuery.proxy(function (e) {
            this.toggleOnOff(e);
          }, this),
        );
        $body.on(
          'click',
          '.nav-tabs li a',
          jQuery.proxy(function (e) {
            this.onModalTabClick(e);
          }, this),
        );
        $promoLiteBelt.on(
          'click',
          jQuery.proxy(function (e) {
            this.onPromo23PercentLiteClick(e);
          }, this),
        );
      },

      pageHasSideNav: function () {
        return $sideNav.length >= 1;
      },

      openActiveSideNavSubmenu: function () {
        $activeSideNavLink.next('ul').show();
      },

      animateRaportsNavLink: function () {
        var raportsLink = jQuery('.raports-fade').children('a');
        var raportsTxt = raportsLink.children('span.raports-txt');
        var raportsIcon = raportsLink.children('span.raports-ico');

        jQuery('.raports-fade').mouseenter(function () {
          raportsTxt.text(raportsLink.attr('text-over'));
          raportsIcon.html(
            '<svg class="icon green icon-small m5r"><use xlink:href="#icon-fm-files"/></svg>',
          );
        });
        jQuery('.raports-fade').mouseleave(function () {
          raportsTxt.text(raportsLink.attr('text-out'));
          raportsIcon.html(
            '<svg class="icon green icon-small m5r"><use xlink:href="#icon-fm-msg"/></svg>',
          );
        });
      },

      openClickedSubmenu: function (e) {
        var clickedLink = jQuery(e.currentTarget);

        if (
          clickedLink.hasClass('active') &&
          !clickedLink.hasClass('clickable')
        ) {
          return false;
        } else {
          if (clickedLink.parent('li').children('ul').length > 0) {
            jQuery('.navbar-left-container > ul > li > a.active').removeClass(
              'active',
            );
            jQuery('.navbar-left-container > ul > li > ul').hide();

            clickedLink.addClass('active');
            var openedSubmenu = clickedLink.next('ul');
            openedSubmenu.show();
          }
          if (clickedLink.attr('href') == '') {
            return false;
          }
        }
      },

      onModalTabClick: function (e) {
        jQuery(e.currentTarget).tab('show');
        Utils.modal.scrollRefresh();
      },

      initLanguageToggles: function () {
        switch_lang_menu('#language_top');
        switch_lang_menu('#first_pass_top');
      },

      hideEmptyNotificationsBadge: function () {
        if (jQuery.trim($notificationsBadge.text()) == '0') {
          $notificationsBadge.hide();
        } else {
          $notificationsBadge.show();
        }
      },

      toggleOnOff: function (e) {
        var $el = jQuery(e.currentTarget),
          isActive = $el.hasClass('on');

        $el.toggleClass('on', !isActive).toggleClass('off', isActive);
      },

      closeBottomAlert: function (e) {
        var alert = jQuery(e.currentTarget).closest('.js-alert-bottom');
        alert.css({
          bottom: '-140px',
        });

        e.preventDefault();
      },

      sendActivationMail: function (e) {
        var linkEl = jQuery(e.target),
          self = this;

        if (linkEl.hasClass('disabled')) {
          return false;
        }

        Loader.on();
        linkEl.addClass('disabled');

        jQuery.ajax({
          url: linkEl.attr('action'),
          dataType: 'json',
          type: 'POST',
          success: function (response) {
            Loader.hide();
            linkEl.removeClass('disabled');
            Success.show(response.message);
            self.closeBottomAlert(e);
          },
        });
      },

      showOccasionPop: function () {
        // To show occasional popup set data-enabled = true in div.js-occasion-pop-conf
        var occasionPopData = jQuery('.js-occasion-pop-conf').data(),
          occasionPopCookie = readCookie('occasion-pop');

        if (
          occasionPopData.enabled == true &&
          occasionPopCookie != occasionPopData.name
        ) {
          Utils.modal.open(occasionPopData.url, 435, 640);
          createCookie('occasion-pop', occasionPopData.name, 365);
        }
      },

      showMMDBelt: function () {
        var self = this;
        setTimeout(function () {
          if (readCookie('mmd2017') != 1) {
            $MMDBelt.addClass('shown');
          }
        }, 1000);
      },
      showGlobalRebateBelt: () => {
        setTimeout(() => {
          if (readCookie('globalRebate2021') != 1) {
            $globalRebateBelt.addClass('shown');
          }
        }, 1000);
      },

      onMMDBeltClose: function (e) {
        createCookie('mmd2017', '1', 365);
        this.closeBottomAlert(e);
      },

      onGlobalRebateBeltClose: (e) => {
        createCookie('globalRebate2021', '1', 365);
        this.closeBottomAlert(e);
      },

      onWebinarBeltClose: function (e) {
        createCookie('webinar2020', '1', 365);
        this.closeBottomAlert(e);
      },

      showHelloBelt: function () {
        var self = this;
        setTimeout(function () {
          if (
            readCookie('hellobelt') < 3 &&
            readCookie('hellobelttotal') != 1
          ) {
            $helloBelt.addClass('shown');
          }
        }, 1000);
      },

      showBlogPostBelt: function () {
        var self = this;
        setTimeout(function () {
          if (readCookie('blogpostbelt') != 1) {
            $blogPostBelt.addClass('shown');
          }
        }, 1000);
      },

      onHelloBeltClose: function (e) {
        var helloCounter = readCookie('hellobelt');
        if (helloCounter === null) helloCounter = 0;
        createCookie('hellobelt', parseInt(helloCounter) + 1, 90);
        createCookie('hellobelttotal', '1', 7);
        $helloBelt.removeClass('shown');
        this.closeBottomAlert(e);
      },

      onBlogPostBeltClose: function (e) {
        createCookie('blogpostbelt', 1, 360);
        $blogPostBelt.removeClass('shown');
        this.closeBottomAlert(e);
      },

      showBottomBelt: function () {
        if ($bottomBelt.length) {
          setTimeout(function () {
            $bottomBelt.addClass('shown');
          }, 1000);
        }
      },

      onBottomBeltClose: function (e) {
        createCookie(
          e.target.dataset.closingCookie,
          '1',
          e.target.dataset.cookieTime,
        );
        this.closeBottomAlert(e);
      },

      onPromo23PercentLiteClick: function (e) {
        e.preventDefault();
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          dataLayer.push(arguments);
        };
        gtag('js', new Date());
        gtag('config', jQuery('#php-data-default').data('frontgtm'));
        gtag('event', 'clickPromo23PercentLite', {
          page_title: 'FreshMail',
        });
        window.location.href = $promoLiteBelt.data('href');
      },
    });

    return fmApp;
  })();

  var app = {
    run: function () {
      new fmApp();
    },
  };

  app.run();
});

// Global functions

var keys = [37, 38, 39, 40];

function keydown(e) {
  for (var i = keys.length; i--; ) {
    if (e.keyCode === keys[i]) {
      preventDefault(e);
      return;
    }
  }
}

function createCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = '; expires=' + date.toGMTString();
  } else var expires = '';
  document.cookie =
    name + '=' + value + expires + '; path=/;SameSite=None; Secure;';
}

function readCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// Sortowanie list
function fnSortList(event) {
  var objSortBtn = jQuery(event.currentTarget);

  if (objSortBtn.hasClass('desc')) {
    jQuery('.inline-sort > a').removeClass('active asc desc');
    objSortBtn.addClass('active asc');
  } else {
    jQuery('.inline-sort > a').removeClass('active asc desc');
    objSortBtn.addClass('active desc');
  }
  tags = new Array();
  adv = new Array();

  jQuery('.dataList input').each(function () {
    if (
      jQuery(this).closest('li').hasClass('campaignTag') &&
      jQuery(this).is(':checked')
    ) {
      tags.push(jQuery(this).val());
    } else if (jQuery(this).is(':checked')) {
      adv.push(jQuery(this).val());
    }
  });

  event.data.pager.setAjaxParameters({
    advanced: adv,
    tags: tags,
    searchPhrase: jQuery('#liveSearch').val(),
    sortBy: objSortBtn.children('.sort-arrow').attr('tag'),
    sortOrder: objSortBtn.hasClass('desc') ? 'desc' : 'asc',
  });

  localStorage.setItem(
    window.location.host + window.location.pathname + '?sort',
    objSortBtn.attr('tag'),
  );
  localStorage.setItem(
    window.location.host + window.location.pathname + '?order',
    objSortBtn.hasClass('down') ? 'desc' : 'asc',
  );

  event.data.pager.restart();
}

// Automatyczne pozycjonowanie tooltipow
function getPlacement(tip, element) {
  var attrPlacement = jQuery(element).attr('data-placement');
  if (typeof attrPlacement !== 'undefined' && attrPlacement !== false) {
    return attrPlacement;
  } else {
    var offset = jQuery(element).offset();
    var offsetT = jQuery(element).offset().top;
    var offsetL = jQuery(element).offset().left;
    var heightThis = jQuery(element).outerHeight() / 2;
    var navHeight = jQuery('.navbar').outerHeight();
    var scrollT = jQuery(window).scrollTop();
    var offsetTopTrue = offsetT - scrollT - navHeight - 2;
    var heightTrue = jQuery(window).height() - navHeight - heightThis;
    var width = jQuery(window).outerWidth();
    var widthThis = jQuery(element).outerWidth() / 2;
    var mainOffset = width - (offsetL + widthThis * 2);
    var vert = 0.5 * heightTrue - offsetTopTrue - heightThis;
    var vertPlacement = vert > 0 ? 'bottom' : 'top';
    var horiz = 0.5 * width - offsetL - widthThis;
    var horizPlacement = horiz > 0 ? 'right' : 'left';
    var placement =
      Math.abs(horiz) > Math.abs(vert) ? horizPlacement : vertPlacement;
    if (mainOffset < 240) {
      return placement;
    } else {
      if (offsetTopTrue < 50) {
        return 'bottom';
      }
      if (offsetTopTrue + 50 > heightTrue) {
        return 'top';
      } else {
        return 'right';
      }
    }
  }
}

// Przełączanie języków
function switch_lang_menu($menu_id) {
  jQuery($menu_id + ' .drop-lang img').on('click', function () {
    var lang = jQuery(this).attr('alt');
    jQuery($menu_id + ' > img').attr('src', jQuery(this).attr('src'));
    jQuery.ajax({
      url: jQuery($menu_id + ' a').attr('data-link'),
      data: {
        lang: lang,
      },
      dataType: 'json',
      type: 'POST',
      success: function (response) {
        if (response.status) {
          tabPath = location.pathname.replace('//', '/').split('/');
          tabPath[1] = lang;
          strPath = tabPath.join('/');
          window.location =
            window.location.protocol +
            '//' +
            window.location.host +
            strPath +
            window.location.search +
            window.location.hash;
        } else {
          Success.show(response.msg);
        }
      },
    });
  });
}

/**
 Example usage:

 var LazyLoad = new InfinityScroll();

 LazyLoad.on('infinity:trigger', function(){
    $('.app-btn-next-page').trigger('click');
 });

 */
var InfinityScroll = (function () {
  'use strict';

  var InfinityScroll = function (options) {
    this.options = options || { offset: 0.2 };
    this.bindEvents();
    this.run();
  };

  _.extend(
    InfinityScroll.prototype,
    {
      run: function () {
        this.$window = $(window);
        this.$document = $(document);
        this.calculateParams();
        this.enableTrigger();

        this.$document.on('scroll', $.proxy(this.onScroll, this));
        this.$window.on('resize', $.proxy(this.onResize, this));
      },

      onScroll: function () {
        if (this.canTrigger()) {
          this.trigger('infinity:trigger');
        }
      },

      onResize: function () {
        this.calculateParams();
      },

      canTrigger: function () {
        if (
          this._height - this.getScrollPosition() < this._offset &&
          this.isTriggerEnabled()
        ) {
          this.disableTrigger();
          return true;
        }

        if (this.getScrollPosition() > this._height) {
          this.calculateParams();
        }

        return false;
      },

      getWindowHeight: function () {
        return this.$window.height();
      },

      getDocumentHeight: function () {
        return this.$document.height();
      },

      getScrollTop: function () {
        return this.$document.scrollTop();
      },

      getScrollPosition: function () {
        return this.getScrollTop() + this._windowHeight;
      },

      getOffset: function () {
        return this.getWindowHeight() * this.options.offset;
      },

      disableTrigger: function () {
        this.triggered = false;
      },

      enableTrigger: function () {
        this.triggered = true;
      },

      isTriggerEnabled: function () {
        return this.triggered;
      },

      calculateParams: function () {
        if (this._height !== this.getDocumentHeight()) this.enableTrigger();
        this._height = this.getDocumentHeight();
        this._windowHeight = this.getWindowHeight();
        this._offset = this.getOffset();
      },

      bindEvents: function () {
        this.on('infinity:refresh', function () {
          this.calculateParams();
        });
      },
    },
    Backbone.Events,
  );

  return InfinityScroll;
})();

var Helpers = window.Helpers || {};

Helpers.SnapshotArrowHelper = (function () {
  var KEY_CODE_LEFT_ARROW = 37,
    KEY_CODE_RIGHT_ARROW = 39,
    _urls,
    _current,
    isEnabled = function () {
      return _.isArray(_urls);
    },
    /**
     * Returns url of next modal and increments counter
     * @return {String} url of next modal or null if arrows are not enabled
     */
    getNext = function () {
      if (!isEnabled()) {
        return null;
      }
      _current++;
      if (!_urls[_current]) {
        _current = 0;
      }
      return _urls[_current];
    },
    /**
     * Returns url of previous modal and decrements counter
     * @return {String|null} url of prev modal or null if arrows are not enabled
     */
    getPrev = function () {
      if (!isEnabled()) {
        return null;
      }
      _current--;
      if (!_urls[_current]) {
        _current = _urls.length - 1;
      }
      return _urls[_current];
    },
    /**
     * Refresh content in current modal
     * @param url
     */
    refreshModal = function (url) {
      if (!url) return;
      Utils.modal.dataContent(url, jQuery('.modal'));
    },
    /**
     * Loads data of next modal
     * @param e
     */
    goNext = function (e) {
      refreshModal(getNext());
      e.preventDefault();
    },
    /**
     * Loads data of previous modal
     */
    goPrev = function (e) {
      refreshModal(getPrev());
      e.preventDefault();
    },
    onNextKeyboard = function (e) {
      if (e.keyCode === KEY_CODE_RIGHT_ARROW) {
        goNext(e);
      }
    },
    onPrevKeyboard = function (e) {
      if (e.keyCode === KEY_CODE_LEFT_ARROW) {
        goPrev(e);
      }
    };

  return {
    /**
     * @param {[]} urls
     * @param {Number} current index from 0 to n-1
     */
    init: function (urls, current) {
      _urls = urls;
      _current = current;
    },

    /**
     * @return {void}
     */
    disable: function () {
      _urls = undefined;
      _current = undefined;
    },

    /**
     * Register arrow handler.
     * Hides arrow handler if in not enabled.
     */
    registerClickHandler: function () {
      var $modal = jQuery('.modal');

      if (!isEnabled()) {
        jQuery('.app-btn-next, .app-btn-prev', $modal).hide();
      } else {
        jQuery('.app-btn-next', $modal).on('click', goNext);
        jQuery('.app-btn-prev', $modal).on('click', goPrev);

        $(document)
          .off('keyup', onNextKeyboard)
          .off('keyup', onPrevKeyboard)
          .on('keyup', onNextKeyboard)
          .on('keyup', onPrevKeyboard);

        $modal
          .on('hidden', $.proxy(this.disable, this))
          .on('hidden', function () {
            $(document)
              .off('keyup', onNextKeyboard)
              .off('keyup', onPrevKeyboard);
          });
      }
    },
  };
})();

// Bind toggla do zmiany języka
window.addEventListener(
  'load',
  function () {
    var langToggle = document.getElementById('language_top');
    var langToggleEl = jQuery(langToggle);
    var touchBlocker = langToggleEl.find('.touch-blocker');

    if (langToggle !== null) {
      if (parseInt(FM.mobile)) {
        touchBlocker.show();
        langToggle.addEventListener(
          'touchend',
          function (e) {
            langToggleEl.addClass('opened');
            setTimeout(function () {
              touchBlocker.hide();
            }, 600);
          },
          false,
        );
      } else {
        langToggle.addEventListener(
          'mouseover',
          function (e) {
            langToggleEl.addClass('opened');
          },
          false,
        );

        langToggle.addEventListener(
          'mouseout',
          function (e) {
            langToggleEl.removeClass('opened');
          },
          false,
        );
      }
    }
  },
  false,
);
