/*
 * form.js - obs�uga formularzy.
 * Dependencje: Utils.changes
 */

Utils.form = new Object();
Utils.form.initialState = '';
Utils.form.form = '';
Utils.form.checkIfClosed = true;

Utils.form.disableButtons = function () {
  jQuery('.btn-next').addClass('disabled');
  jQuery('.btn-commit').addClass('disabled');
  jQuery('.btn-next.fm-btn').addClass('fm-btn--disabled');
};

Utils.form.isButtonDisabled = function () {
  return jQuery('.btn-commit').hasClass('disabled');
};

Utils.form.enableButtons = function () {
  jQuery('.btn-next').removeClass('disabled');
  jQuery('.btn-next.fm-btn').removeClass('fm-btn--disabled');
  jQuery('.btn-commit').removeClass('disabled');
};

Utils.form.focusOnError = function (form) {
  if (typeof form === 'undefined') {
    form = jQuery('.main-form');
  }

  if (form.find('input.wrong:first').length == 0) {
    return;
  }

  position = form.find('input.wrong:first').offset();

  if (position.top) {
    jQuery(window)
      .stop()
      .scrollTo(
        {
          top: position.top - 200,
          left: 0,
        },
        300,
        function () {
          form.find('input.wrong:first').focus();
        },
      );
  } else {
    form.find('input.wrong:first');
  }

  form.find('input[type="text"]').bind('change', function () {
    Validation.onFocusOut(jQuery(this));
  });
};

Utils.form.liveValidation = function (form) {
  form
    .find('input:not(".doNotCheck")[type="text"]')
    .bind('change', function () {
      Validation.onFocusOut(jQuery(this));
    });
};

Utils.form.validation = function (form) {
  var valid = true;

  // TODO Refactor js valiadators
  $('input[type="text"]:not(.doNotCheck):visible', form).each(function () {
    if (Validation.onFocusOut(jQuery(this)) == false) {
      valid = false;
    }
  });

  $('input[type="email"]:not(.doNotCheck):visible', form).each(function () {
    if (Validation.onFocusOut(jQuery(this)) == false) {
      valid = false;
    }
  });

  $('input[type="password"]:not(.doNotCheck):visible', form).each(function () {
    if (Validation.onFocusOut(jQuery(this)) == false) {
      valid = false;
    }
  });

  Utils.form.focusOnError(form);

  if (valid == true) {
    Utils.form.checkIfClosed = false;
  } else {
    const changesDial = document.querySelector('.changesDial') || {};
    changesDial.style.display = 'none';
  }

  return valid;
};

//EXAMPLE USAGE - Utils.form.confirmExit(jQuery('form.checkChanges :input:not(".dontCheck")')); - SPACE between form and :input IS NESSESERY!!!(nesesery ;D)
Utils.form.confirmExit = function (form) {
  Utils.form.form = form;
  Utils.form.initialState = Utils.form.form.serialize();
  jQuery('body').on(
    'click',
    'a:not(".cke_off,.cke_dialog_close_button, [id^=cke_], [class^=cke_]"):not(.create-new-prevent-form-event), .btn-link',
    function (e) {
      return Utils.form.confirmEvent(jQuery(this), e);
    },
  );

  window.onbeforeunload = function (e) {
    if (Utils.form.isFormChanged == false && Utils.form.checkIfClosed == true) {
      return FM.l10n.common.leave;
    } else {
      return;
    }
  };
};

Utils.form.isFormChanged = function () {
  var re = new RegExp(/%20\s*/g);
  var newData = Utils.form.form.serialize().replace(re, '');
  var oldData = Utils.form.initialState.replace(re, '');

  if (Utils.form.form && oldData != newData) {
    return true;
  }

  return false;
};

Utils.form.confirmEvent = function (element, e) {
  if (
    element.hasClass('dropdown-button') ||
    element.attr('data-toggle') == 'modal' ||
    element.attr('target') == '_blank' ||
    typeof element.attr('href') === 'undefined' ||
    element.attr('href') === false ||
    element.attr('href') == '' ||
    element.attr('href').indexOf('#') == 0
  ) {
    return true;
  }

  if (!Utils.form.isFormChanged()) {
    return true;
  }

  Utils.changes.show(element.attr('href'), e);
  return false;
};
