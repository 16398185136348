/**********************************************************************************************
 * Modal.js
 * Modal jest responsywny, nie wymaga przeliczania wysoko�ci i szerokosci po zmianie wielkosci
 * okna. Modal ma 3 rodzaje wielko�ci: responsywny pe�ny ekran, 940px szeroko�ci i r�czny.
 *
 **********************************************************************************************/
Utils.modal = {};
if (!Utils.modal) {
  Utils.modal = {};
}
if (typeof Utils?.modal === 'object') {
  Utils.modal.opened = false;
}

Utils.modal.close = function () {
  if (FM.animate == 1) {
  } else {
    jQuery('.main-loader-wrapper').css('opacity', 0);
    jQuery('.main-loader-wrapper').css('left', '-7000px');
  }

  Utils.modal.opened = false;
  Utils.modal.scrollEnable();

  // NIE USUWAC TEGO EVENTA
  jQuery('.modal').trigger('modalClosed');
  jQuery('.modal').remove();

  Utils.modal.destroy();
};

/*
 * Operacje na scrollu w body
 **************************************/
Utils.modal.scrollRefresh = function () {
  jQuery('.modal-scroll').css('height', Utils.modal.getComputedHeight());
  jQuery('.modal-scroll').getNiceScroll().resize();
};

Utils.modal.scrollDisable = function () {
  jQuery('body').css('overflow', 'hidden');
};

Utils.modal.scrollEnable = function () {
  jQuery('body').removeAttr('style');
};

/*
 * Ustawianie wielko�ci modala
 **************************************/
Utils.modal.setComputed = function (modal, height, width) {
  if (
    jQuery('.modal-wrapper > .modal-header').length > 0 &&
    !jQuery('.modal-footer').hasClass('hide')
  ) {
    var footer = jQuery('.modal-footer').innerHeight();
  } else {
    var footer = 0;
  }

  if (
    jQuery('.modal-wrapper > .modal-header').length > 0 &&
    !jQuery('.modal-header').hasClass('hide')
  ) {
    var header = jQuery('.modal-header').innerHeight();
  } else {
    var header = 0;
  }

  if (height < jQuery(window).innerHeight()) {
    modal.css({
      height: height - footer - header,
      'margin-top': -(height / 2),
      top: '50%',
    });

    if (typeof height == 'undefined') {
      height = jQuery(window).innerHeight() - footer - header;
      modal.css({
        height: height,
        'margin-top': -(height / 2),
        top: '50%',
      });
    }
  }
  if (width < jQuery(window).innerWidth()) {
    modal.css({
      width: width,
      'margin-left': -(width / 2),
      left: '50%',
    });
  }
};

/*
 * Ustalanie nowych wymiarów modala
 **************************************/

Utils.modal.setNewSize = function (height, width) {
  var modal = jQuery('.modal');
  var windowHeight = jQuery(window).innerHeight();
  var windowWidth = jQuery(window).innerWidth();

  if (height === false) {
    var modalBody = jQuery('.modal .modal-body');
    var autoHeight = jQuery(window).innerHeight(); //Utils.modal.getFooterHeight() + Utils.modal.getHeaderHeight() + modalBody.height();
    height = autoHeight;
  }

  if (height > windowHeight) {
    height = windowHeight - 100;
  }
  if (width > windowWidth) {
    width = windowWidth - 100;
  }

  modal.css({
    height: height,
    'margin-top': -height / 2,
    width: width,
    'margin-left': -width / 2,
    top: '50%',
    left: '50%',
  });
};

/*
 * Ustalanie automatycznej wysokości modala
 **************************************/
Utils.modal.resizeToAutoHeight = function () {
  var modal = jQuery('.modal');

  var bodyNaturalHeight = jQuery('.modal .modal-body').outerHeight();
  var bodyComputedHeight = jQuery('.modal .modal-scroll').outerHeight();

  var maxModalMargin = 20;
  var maxModalHeight = jQuery(window).innerHeight() - maxModalMargin;
  var autoHeight =
    Utils.modal.getFooterHeight() +
    Utils.modal.getHeaderHeight() +
    bodyNaturalHeight;

  var autoHeightFlagClass = 'autoheight-applied';

  var shouldApplyAutoheight =
    !modal.hasClass(autoHeightFlagClass) ||
    (modal.hasClass(autoHeightFlagClass) &&
      bodyNaturalHeight >= bodyComputedHeight);

  if (shouldApplyAutoheight && autoHeight > 0 && maxModalHeight > 0) {
    if (autoHeight < maxModalHeight) {
      modal.animate(
        {
          height: autoHeight,
          'margin-top': -autoHeight / 2,
          top: '50%',
        },
        300,
      );
    } else {
      modal.animate(
        {
          height: maxModalHeight,
          'margin-top': -maxModalHeight / 2,
          top: '50%',
        },
        300,
      );
    }

    setTimeout(Utils.modal.scrollRefresh, 400);
    modal.addClass(autoHeightFlagClass);
  }
};

/*
 * Usuwanie modala
 **************************************/
Utils.modal.destroy = function () {
  jQuery('.modal,.modal-backdrop').remove();
};

/*
 * Obs�uga loadera
 **************************************/
Utils.modal.loaderIn = function (modal) {
  var loader =
    '<div class="modal-loader"><svg class="table-spinner"><use xlink:href="#icon-fm-spinner"></use></svg></div>';
  modal.prepend(loader);

  modal
    .find('.modal-loader')
    .delay(200)
    .queue(function () {
      // Powi�kszenie loadera
      jQuery(this).addClass('scale').delay(300).dequeue();
    });
};

Utils.modal.loaderOn = function (modal) {
  modal.find('.modal-loader').addClass('scale').show();
};

Utils.modal.loaderOut = function (modal) {
  modal
    .find('.modal-loader')
    .delay(200)
    .queue(function () {
      // Wyďż˝wietlenie wrappera treďż˝ci
      modal.find('.modal-wrapper').show();
      // Ukrycie loadera
      jQuery(this).fadeOut(200).dequeue();
    });
};

Utils.modal.loaderReset = function (modal) {
  modal.find('.modal-loader').removeClass('scale');
};

/*
 * Nawigacja modali
 **************************************/
Utils.modal.navigation = function (modals, url, modal, steps) {
  if (typeof modals != 'undefined') {
    //modals = modals.split(',');
    var pagination = jQuery('<ul class="modal-navigation"></ul>');
    var num;

    for (i in modals) {
      num = parseInt(i) + 1;
      if (steps == true) {
        if (url == modals[i].link) {
          pagination.append(
            '<li><a href="' +
              modals[i].link +
              '" data-toggle="modal" data-target="#myModal" class="btn btn-primary">' +
              num +
              '. ' +
              modals[i].title +
              '</a></li>',
          );
        } else {
          pagination.append(
            '<li><a data-href="' +
              modals[i].link +
              '" data-toggle="modal" data-target="#myModal" class="btn disabled">' +
              num +
              '. ' +
              modals[i].title +
              '</a></li>',
          );
        }
      } else {
        if (url == modals[i].link) {
          pagination.append(
            '<li><a href="' +
              modals[i].link +
              '" data-toggle="modal" data-target="#myModal" class="btn btn-primary">' +
              num +
              '. ' +
              modals[i].title +
              '</a></li>',
          );
        } else {
          pagination.append(
            '<li><a href="' +
              modals[i].link +
              '" data-toggle="modal" data-target="#myModal" class="btn">' +
              num +
              '. ' +
              modals[i].title +
              '</a></li>',
          );
        }
      }
    }
    // Dodanie ekstra paddingu w headerze aby nawigacja dobrze wygl�da�a.
    modal.append(pagination);
  }
};

/*
 * Aktywowanie/deaktywowanie link�w
 **************************************/

Utils.modal.navigation.disable = function (url) {
  jQuery('.modal-navigation')
    .find("a[href='" + url + "']")
    .addClass('disabled')
    .removeAttr('href')
    .attr('data-src', url);
};

Utils.modal.navigation.enable = function (url) {
  jQuery('.modal-navigation')
    .find("a[data-href='" + url + "']")
    .removeClass('disabled')
    .removeAttr('data-src')
    .attr('href', url);
};

Utils.modal.navigation.nextLinks = function () {
  var currentButton = jQuery('.modal-navigation').find('a.btn-primary');
  var currentHref = currentButton.attr('href');

  currentButton.attr('data-href', currentHref);

  if (currentButton.parent().next().is('li')) {
    var nextHref = currentButton.parent().next().children().data('href');
    currentButton
      .parent()
      .next()
      .children()
      .attr('href', nextHref)
      .removeClass('disabled');
  } else if (currentButton.parent().prev().is('li')) {
    var prevHref = currentButton.parent().prev().children().data('href');
    currentButton
      .parent()
      .prev()
      .children()
      .attr('href', prevHref)
      .removeClass('disabled');
  }
};

/*
 * Definiowanie aktywnego modala w modalu
 **************************************/
Utils.modal.navigation.active = function (url) {
  var pagination = jQuery('.modal-navigation');
  pagination.find('a').removeClass('btn-primary');
  pagination.find('a[href="' + url + '"]').addClass('btn-primary');
};
/*
 * Aktywowanie wyszystkich linków przed active - nie działa prawidłowo jeszcze
 **************************************/
Utils.modal.navigation.prevActive = function () {
  var pagination = jQuery('.modal-navigation');
  pagination
    .find('.btn-primary')
    .parent()
    .prevAll()
    .find('a')
    .removeClass('disabled');
};

/*
 * Dodanie scrolla
 **************************************/
Utils.modal.scroll = function () {
  const setScrollModal = () => {
    setTimeout(function () {
      const modalScroll = jQuery('.modal-scroll');
      if (modalScroll?.length > 0) {
        modalScroll.css('height', Utils.modal.getComputedHeight());

        modalScroll.niceScroll();
      } else {
        setScrollModal();
      }
    }, 600);
  };

  setScrollModal();
};

Utils.modal.getComputedHeight = function () {
  if (
    jQuery('.modal-wrapper .modal-footer').length > 0 &&
    !jQuery('.modal-footer').hasClass('hide')
  ) {
    var footer = jQuery('.modal-footer').innerHeight();
  } else {
    var footer = 0;
  }

  if (
    jQuery('.modal-wrapper .modal-header').length > 0 &&
    !jQuery('.modal-header').hasClass('hide')
  ) {
    var header = jQuery('.modal-header').innerHeight();
  } else {
    var header = 0;
  }

  return jQuery('.modal-wrapper').height() - footer - header;
};

Utils.modal.getFooterHeight = function () {
  if (
    jQuery('.modal-wrapper .modal-footer').length > 0 &&
    !jQuery('.modal-footer').hasClass('hide')
  ) {
    var footer = jQuery('.modal-footer').innerHeight();
  } else {
    var footer = 0;
  }

  return footer;
};

Utils.modal.getHeaderHeight = function () {
  if (
    jQuery('.modal-wrapper .modal-header').length > 0 &&
    !jQuery('.modal-header').hasClass('hide')
  ) {
    var header = jQuery('.modal-header').innerHeight();
  } else {
    var header = 0;
  }

  return header;
};
/*
 * Otwarcie modala
 **************************************/
Utils.modal.open = function (
  url,
  height,
  width,
  auto,
  modals,
  steps,
  backdrop,
  refresh,
) {
  var modalWrapper = jQuery('<div class="modal-wrapper"></div>');

  if (Utils.modal.opened === true && jQuery('.modal-wrapper').length > 0) {
    Utils.modal.loaderOn(modal);
    Utils.modal.dataContent(url, modal);
  } else {
    /*
      ZMIENNA modal MUSI BYĆ GLOBALNA!!! NIE DODAWAĆ var modal...
     */
    window.modal = jQuery(
      '<div class="modal full" data-backdrop="' + backdrop + '"></div>',
    );
    var options = {
      backdrop: backdrop,
      keyboard: false,
    };

    if (auto == true) {
      var modalWidth = width != undefined ? width : 990;
      Utils.modal.dataContent(url, modal, auto);
      Utils.modal.setComputed(modal, 300, modalWidth);
    } else {
      Utils.modal.dataContent(url, modal, auto, refresh);
      Utils.modal.setComputed(modal, height, width);
    }
    Utils.modal.scrollDisable();
    Utils.modal.loaderIn(modal);
    modal.append(modalWrapper);

    modal.modal(options);

    if (backdrop !== 'static') {
      $('.modal-backdrop').on('click', function () {
        Utils.modal.close();
      });
    }
  }

  if (modals !== null || steps !== null) {
    Utils.modal.navigation(modals, url, modal, steps);
    Utils.modal.navigation.active(url);
  }

  return modal;
};

Utils.modal.Verification3DS = function (html, title, blockedAddsNotice) {
  script = '';
  jQuery('.modal,.modal-backdrop').remove();
  jQuery('<div class="modal-backdrop  in"></div>').appendTo('body');

  template = _.template(
    '<div style="display: block;" class="modal-wrapper">\
                            <div class="modal-script" script="<%= script %>" style="height:auto"></div>\
                            <div class="modal-header text-center">\
                                <h2>\
                                    <%= title %>\
                                </h2>\
                            </div>\
                            <div tabindex="5001" style="height: 560px; overflow: hidden; position: relative; font-size: 16px;" class="modal-scroll new-message-modal text-center">\
                                <div style="transform: translate3d(0px, 0px, 0px);" class="modal-scroll-inner">\
                                    <div class="modal-body">\
                                        <div class="p20">\
                                        <div class="threeds-modal-image"></div>\
                                        <span class="subhead text-center" id="threeDSVerification"><%= content %></span>\
                                        <div class="blocked-notice"><%= blockedNotice %></div>\
\
                                        </div>\
                                     </div>\
                                </div>\
                            </div>\
                            <div style="width: 7px; z-index: 9002; position: absolute; top: 0px; right: 0px; display: none; height: 430px;" id="ascrail2001"><div style="position: relative; top: 0px; float: right; width: 5px; height: 0px; border: 1px solid rgb(172, 208, 55); background-clip: padding-box; border-radius: 5px;"></div></div><div style="height: 8px; z-index: 9002; position: absolute; left: 0px; bottom: 0px; display: none; width: 960px;" id="ascrail2001-hr"><div style="position: relative; top: 0px; height: 5px; width: 0px; border: 1px solid rgb(172, 208, 55); background-clip: padding-box; border-radius: 5px;"></div></div></div>\
                            </div>',
  );

  jQuery(
    '<div class="modal" style="height: 360px; margin-top: -250px; top: 50%; max-width: 680px; margin-left: -340px; left: 50%;"></div>',
  )
    .html(
      template({
        content: html,
        title: title,
        blockedNotice: blockedAddsNotice,
      }),
    )
    .appendTo('body');
  Utils.popover.refresh();
};

/*
 * Weryfikacja jsona
 **************************************/

Utils.modal.dataVerify = function (data, request) {
  if (request.getResponseHeader('Content-Type') === 'application/json') {
    data = data.html;
  } else if (request.getResponseHeader('Content-Type') === 'text/html') {
    try {
      var checkJSON = jQuery.trim(data);
      if (checkJSON[0] == '{' && checkJSON[checkJSON.length - 1] == '}') {
        location.reload();
        return false;
      }
    } catch (err) {
      location.reload();
    }
  }
  return data;
};

/*
 * Tre�� modala
 **************************************/
Utils.modal.dataContent = function (url, modal, auto, refresh) {
  jQuery.ajax({
    url: url,
    success: function (data, textStatus, request) {
      data = Utils.modal.dataVerify(data, request);

      // Dodanie wrappera na tresc
      modal.find('.modal-wrapper').html(data);

      // Wy��czenie loadera

      var jsaction = jQuery(data).find('form').attr('jsaction');
      if (jsaction) {
        Lemon[jsaction]();
      }
      // dodałem klase na script - modal-script
      var jsScript = modal.find('.modal-script:first').attr('script');
      if (jsScript) {
        jQuery('body').append(
          '<script src="' + jsScript + '" type="text/javascript"></script>',
        );
      }
    },
    error: function () {
      jQuery(
        '<div class="modal hide"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">�</button><div class="modal-scroll"><div class="modal-body"> <p style="padding: 10px;">Error</p> </div></div></div>',
      )
        .filter('.modal')
        .modal();
    },
    complete: function () {
      jQuery('.modal').waitForImages(function () {
        function myModal(callback) {
          callback();
        }

        if (jQuery('#countdown').length >= 1) {
          countdown();
        }

        myModal(function () {
          if (jQuery('.feedback-widget').length >= 1) {
            sizeFeedbackWidget();
          }
          if (auto == true || refresh == true) {
            var heightInterval = setInterval(function () {
              updateModalHieght();
            }, 600);

            var updateModalHieght = function () {
              if (jQuery('.modal-body').height() > 0) {
                if (refresh == true) {
                  Utils.modal.scrollRefresh();
                } else {
                  Utils.modal.resizeToAutoHeight();
                }
                clearInterval(heightInterval);
              }
            };
          }

          if (
            jQuery('#historyTable').length > 0 ||
            (jQuery('.single-screen').length > 0 &&
              jQuery('.single-screen').filter('.single-screen-no-wait')
                .length == 0) ||
            jQuery('.subscriber-container').length > 0 ||
            jQuery('.notification-modal').length > 0
          ) {
          } else {
            Utils.modal.loaderOut(modal);
          }

          try {
            control_group();
          } catch (fe) {}
        });
      });
      Utils.modal.scroll();
      Utils.modal.opened = true;
      jQuery('.modal').trigger('modalOpened');
    },
  });
};
