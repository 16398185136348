function isExclamationMark(title) {
  return title?.endsWith('!');
}

function isDot(description) {
  return description?.endsWith('.');
}

var Alert = new Object();
Alert.html =
  '<div class="widget-static-wrapper" style="display: none">' +
  '<div class="alert-widget error-widget">' +
  '<div class="close-icon" />' +
  '<svg class="icon size32"><use xlink:href="#icon-fm-error"/></svg>' +
  '<div class="widget-description">' +
  '<h3 class="second m0">__title__</h3>' +
  '<p>__description__</p>' +
  '</div>' +
  '</div>' +
  '</div>';

Alert.show = function (description, title) {
  if (typeof title == 'undefined') {
    title = FM.l10n.common.error_alert;
  }

  if (isExclamationMark(title)) {
    title = title.slice(0, -1);
  }

  if (!isDot(description)) {
    description = `${description}.`;
  }

  description = jQuery('<div />').html(description).text();
  title = jQuery('<div />').html(title).html();

  html = Alert.html.replace(/__title__/, title);
  html = html.replace(/__description__/, description);
  toggleInfoModal(html);
};

function deleteInfo() {
  jQuery('.info-widget-wrapper div.widget-static-wrapper:first-child').remove();
}

function animateFirst(anim) {
  anim.first = jQuery('.info-widget-wrapper div.widget-static-wrapper:first');
}

function fadeAlertOut(anim) {
  animateFirst(anim);
  TweenLite.to(anim.first, 0.8, {
    css: {
      opacity: 0,
    },
  });
}

let alertTimeout = null;
let alertRemoveTimeout = null;
let startTime = null;
let remaining = 6000;
let animate = null;
function startTimeout() {
  startTime = Date.now();
  clearTimeout(alertTimeout);
  clearTimeout(alertRemoveTimeout);
  alertTimeout = setTimeout(function () {
    fadeAlertOut(animate);
  }, remaining);
  alertRemoveTimeout = setTimeout(function () {
    animateFirst(animate);
    deleteInfo();
  }, remaining + 800);
}

function pauseTimeout() {
  clearTimeout(alertTimeout);
  clearTimeout(alertRemoveTimeout);
  remaining -= Date.now() - startTime;
}
function toggleInfoModal(html) {
  jQuery('.info-widget-wrapper').show();
  jQuery('.info-widget-wrapper').html(html);
  anim = new Object();
  anim.last = jQuery('.info-widget-wrapper div.widget-static-wrapper:last');
  animateFirst(anim);
  anim.last.slideDown();

  animate = anim;
  remaining = 6000;
  startTimeout();

  const closeButton = document.querySelector('.close-icon');
  closeButton?.addEventListener('click', function (e) {
    fadeAlertOut(anim);
    setTimeout(function () {
      animateFirst(anim);
      deleteInfo();
    }, 800);
  });
}

var Success = new Object();
Success.html =
  '<div class="widget-static-wrapper" style="display: none">\
                            <div class="alert-widget success-widget">\
                            <div class="close-icon" />\
                                <svg class="icon size32"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-fm-success"></use></svg>\
				<div class="widget-description">\
				  <h3 class="second m0">__title__</h3>\
				  <p>__description__</p>\
        </div>\
                            </div>\
            </div>';

Success.show = function (description, title) {
  if (typeof title == 'undefined') {
    title = FM.l10n.common.success_alert;
  }

  if (!isExclamationMark(title)) {
    title = `${title}!`;
  }

  if (!isDot(description)) {
    description = `${description}.`;
  }

  description = jQuery('<div />').html(description).text();
  title = jQuery('<div />').html(title).text();

  html = Success.html.replace(/__title__/, title);
  html = html.replace(/__description__/, description);
  toggleInfoModal(html);
};

document.addEventListener('DOMContentLoaded', () => {
  const alertWrapper = document.querySelector('.info-widget-wrapper');
  alertWrapper?.addEventListener('mouseenter', () => pauseTimeout());
  alertWrapper?.addEventListener('mouseleave', () => startTimeout());
});
