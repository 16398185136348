var FM = window.FM || {};

jQuery(function () {
  var backendData =
    document.querySelector('#php-data-auth') ||
    document.querySelector('#php-data-default');
  FM.blockModal = false;
  FM.mobile = backendData.getAttribute('data-mobile');
  FM.animate = backendData.getAttribute('data-animate');
  FM.notifications = backendData.getAttribute('data-notifications');
});

// Otwieranie okna modalnego z kontaktem gdy w URL jest #contact_us
$(window).on('load', function () {
  if (window.location.hash === `#contact_us`) {
    const openModal = jQuery('.open-feedback');
    if (openModal && openModal.length > 0) {
      openModal[0].click();
    }
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search,
    );
  }

  const dashboardDanger = $('.dashboard-alert-danger');
  const alertWithAside = $(
    '.lp_library .dashboard-alert-danger, .app-layout-view .dashboard-alert-danger',
  );

  if (dashboardDanger?.length && alertWithAside?.length === 0) {
    const addMargin = () => {
      const reCheckDashboard = $('.dashboard-alert-danger');
      for (let i = 0; i < reCheckDashboard?.length; i++) {
        if (i === 0) {
          $(reCheckDashboard[i]).removeClass('alert-margin-top');
        } else {
          $(reCheckDashboard[i]).addClass('alert-margin-top');
        }
      }
    };
    addMargin();

    dashboardDanger.find('.icon-close-alert').on('click', function () {
      const parent = $(this).parent();
      const now = new Date();
      const time = now.getTime();
      const expireTime = time + 1000 * 36000;
      now.setTime(expireTime);
      if (parent.hasClass('info-label')) {
        document.cookie =
          'InfoLabel=true;expires=' + now.toUTCString() + ';path=/';
      }

      if (parent.hasClass('error-label')) {
        document.cookie =
          'ErrorLabel=true;expires=' + now.toUTCString() + ';path=/';
      }

      parent.addClass('hide-alert');
      setTimeout(() => {
        parent.remove();
        setTimeout(() => {
          addMargin();
        }, 50);
      }, 300);
    });
  }

  if (alertWithAside?.length) {
    const defaultStyle = { top: '60px', height: 'calc(100% - 60px)' };
    const lpAside = $('aside.span3');

    const setSideNavStyle = () => {
      const errorTop = alertWithAside[0].getBoundingClientRect().top;
      const topNumber = alertWithAside[0].clientHeight + errorTop;
      lpAside.addClass('no-transition');
      lpAside.removeClass('has-alert');

      if (errorTop > -4) {
        lpAside.css({
          top: `${topNumber}px`,
          height: `calc(100% - ${topNumber}px)`,
        });
      } else {
        lpAside.css(defaultStyle);
      }
    };

    alertWithAside.find('.icon-close-alert').on('click', function () {
      lpAside.removeClass('no-transition');
      $(this).parent().addClass('hide-alert');

      setTimeout(() => {
        lpAside.css(defaultStyle);
      }, 125);
    });

    setSideNavStyle();
    document.addEventListener('scroll', setSideNavStyle);
    window.addEventListener('resize', setSideNavStyle);
  }
});

if ('utils' in window.FM === false) {
  FM.utils = {};
} 

FM.utils.externalSources = {};

FM.utils.externalSources.loadScript = (url) => {
  return new Promise(function(resolve, reject) {
    const script = document.createElement('script');
    script.src = url;
    script.onload = function() {
      resolve(url);
    };
    script.onerror = function() {
      reject(new Error('Failed to load script: ' + url));
    };
    document.head.appendChild(script);
  });
};

FM.utils.externalSources.loadExternalScriptsAndInit = async (scripts, callback) => {
  try {
    if (Array.isArray(scripts)) {
      for (const script in scripts) {
        await FM.utils.externalSources.loadScript(scripts[script]);
      }

      callback();
    } else {
      console.error('First argument should be an array.');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};
